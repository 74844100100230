export const leadContractDetail = {
  en: `<div style="display: flex; flex-direction: column; gap: 24px; width: 100%;">
  <h2 style="font-weight: 600; border: 1px solid rgba(0, 0, 0, 0.1); font-size: 30px; margin-bottom: 24px; font-size: 26px;">
    Milestone Details
  </h2>
  <div style="display: grid; grid-template-columns: 1fr; gap: 24px;">
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Landmark 1
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          After you have accepted the offer, a deposit for the trip must be paid no later than 3 days after the agreement is approved. The deposited deposit is forwarded to the airline and hotel. The deposit constitutes 25%.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Landmark 2 (60 days before departure)
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Until [[[MILESTONE_DATE1]]] further reduction of up to 10% can be made without being liable for the seats.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Milestone 3 (45 days before departure)
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          By the [[[MILESTONE_DATE2]]], the name list must be submitted to Yak Sport.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Milestone 4
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Up to 14 days before departure, it is possible to change the names on the name list without extra charge.
        </span>
      </div>
    </div>
  </div>
</div>
`,
  da: `<div style="display: flex; flex-direction: column; gap: 24px; width: 100%;">
  <h2 style="font-weight: 600; border: 1px solid rgba(0, 0, 0, 0.1); font-size: 30px; margin-bottom: 24px; font-size: 26px;">
    Milestone Detaljer
  </h2>
  <div style="display: grid; grid-template-columns: 1fr; gap: 24px;">
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Landmærke 1
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Efter du har accepteret tilbuddet, skal der betales depositum for rejsen senest 3 dage efter godkendelse af aftalen. Det indbetalte depositum sendes videre til flyselskabet og hotellet. Depositummet udgør 25%.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Landmærke 2 (60 dage før afgang)
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Til og med [[[MILESTONE_DATE1]]] kan der foretages yderligere reduktion på op til 10%, uden at hæfte for pladserne.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Milepæl 3 (45 dage inden afrejse)
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Senest den [[[MILESTONE_DATE2]]] indsendes navneliste til Yak Sport.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Milepæl 4
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Op til 14 dage inden afrejse er der mulighed for at ændre navnene på navnelisten uden merbetaling.
        </span>
      </div>
    </div>
  </div>
</div>
`,
  de: `
  <div style="display: flex; flex-direction: column; gap: 24px; width: 100%;">
  <h2 style="font-weight: 600; border: 1px solid rgba(0, 0, 0, 0.1); font-size: 30px; margin-bottom: 24px; font-size: 26px;">
    Meilenstein Details
  </h2>
  <div style="display: grid; grid-template-columns: 1fr; gap: 24px;">
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Landmarke 1
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Nachdem Sie das Angebot angenommen haben, muss spätestens 3 Tage nach Genehmigung des Vertrags eine Anzahlung für die Reise geleistet werden. Die geleistete Anzahlung wird an die Fluggesellschaft und das Hotel weitergeleitet. Die Anzahlung beträgt 25%.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Landmarke 2 (60 Tage vor Abreise)
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Bis zum [[[MILESTONE_DATE1]]] kann eine weitere Reduzierung um bis zu 10% vorgenommen werden, ohne für die Plätze haftbar zu sein.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Meilenstein 3 (45 Tage vor Abreise)
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Bis zum [[[MILESTONE_DATE2]]] muss die Namensliste an Yak Sport übermittelt werden.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Meilenstein 4
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Bis zu 14 Tage vor Abreise ist es möglich, die Namen in der Namensliste ohne zusätzliche Kosten zu ändern.
        </span>
      </div>
    </div>
  </div>
</div>
`,
  sv: `<div style="display: flex; flex-direction: column; gap: 24px; width: 100%;">
  <h2 style="font-weight: 600; border: 1px solid rgba(0, 0, 0, 0.1); font-size: 30px; margin-bottom: 24px; font-size: 26px;">
    Milstolpar Detaljer
  </h2>
  <div style="display: grid; grid-template-columns: 1fr; gap: 24px;">
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Landmärke 1
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Efter att du har accepterat erbjudandet måste en deposition för resan betalas senast 3 dagar efter att avtalet har godkänts. Den deponerade depositionen vidarebefordras till flygbolaget och hotellet. Depositionen utgör 25%.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Landmärke 2 (60 dagar före avresa)
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Fram till [[[MILESTONE_DATE1]]] kan ytterligare minskning med upp till 10% göras utan att vara ansvarig för platserna.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Milstolpe 3 (45 dagar före avresa)
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Senast den [[[MILESTONE_DATE2]]] måste namnlistan skickas till Yak Sport.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Milstolpe 4
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Upp till 14 dagar före avresa är det möjligt att ändra namnen på namnlistan utan extra kostnad.
        </span>
      </div>
    </div>
  </div>
</div>

`,
  no: `<div style="display: flex; flex-direction: column; gap: 24px; width: 100%;">
  <h2 style="font-weight: 600; border: 1px solid rgba(0, 0, 0, 0.1); font-size: 30px; margin-bottom: 24px; font-size: 26px;">
    Milestone-detaljer
  </h2>
  <div style="display: grid; grid-template-columns: 1fr; gap: 24px;">
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Landmerke 1
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Etter at du har akseptert tilbudet, må det betales et depositum for turen senest 3 dager etter at avtalen er godkjent. Det innsatte depositumet videreføres til flyselskapet og hotellet. Depositumet utgjør 25%.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Landmerke 2 (60 dager før avreise)
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Frem til [[[MILESTONE_DATE1]]] kan det gjøres ytterligere reduksjon på opptil 10%, uten å være ansvarlig for plassene.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Milepæl 3 (45 dager før avreise)
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Innen [[[MILESTONE_DATE2]]] må navnelisten sendes til Yak Sport.
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding-left: 24px; padding-right: 24px; padding-bottom: 20px; padding-top: 20px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px;">
      <FlagBlue style="min-width: 28px; min-height: 28px;" />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8);">
          Milepæl 4
        </span>
        <span style="font-size: 16px; color: rgba(0, 0, 0, 0.6); font-size: 14px;">
          Inntil 14 dager før avreise er det mulig å endre navnene på navnelisten uten ekstra kostnad.
        </span>
      </div>
    </div>
  </div>
</div>
`,
};

export const aboutYakSport = {
  en: `<div style="display: flex; flex-direction: column; gap: 28px;">
  <h2 style="font-weight: 600; color: #000; font-size: 30px; margin-bottom: 0; font-size: 26px;">
    Welcome to Yak Sport
  </h2>
  <span style="font-weight: 400; font-size: 18px; color: #666; font-size: 16px;">
    We are a Danish travel agency specializing in training camps for Danish clubs, associations and schools. Every year we send hundreds of Danish sports enthusiasts to Southern Europe and the rest of the world - where the good experience is a common feature of all our trips. At Yak Sport, we focus on:
  </span>
  <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 24px; grid-template-columns: 1fr;">
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <MailBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Good communication with customer
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Fast response time to email and phone
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <MoneyBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Transparent prices
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Where everyone can be with no sudden extra bills for the customer
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <ReloadBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Flexibility with a fair cancellation policy
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Where the customer can cancel up to 10% without being liable for it
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <HotelBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Hotels and training facilities
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          It lives up to the high standard our customers demand
        </span>
      </div>
    </div>
  </div>
  <span style="font-size: 18px; color: #666; max-width: 100%; font-size: 16px;">
    A training trip to Southern Europe would be an ideal choice to kick-start the season, where the squad should only focus on training sessions, personal development and togetherness - And of course it should also be fun!
    <br />
    <br />
    We put together the trip according to the group's specific wishes and needs. As a starting point, this offer contains and presents our standard product, while the possibility of additional purchases such as excursions is noted.
    <br />
    <br />
    We will do everything to ensure that the processing and planning work perfectly, and are of course always available in this regard.<br />
    <br />
    <br />
    If you have any questions about the offer, please feel free to contact us.
    <br />
    Kind regards<br />
    Ibrahim Oniz<br />
    Founder of Yak Sport
  </span>
</div>`,
  da: `<div style="display: flex; flex-direction: column; gap: 28px;">
  <h2 style="font-weight: 600; color: #000; font-size: 30px; margin-bottom: 0; font-size: 26px;">
    Velkommen til Yak Sport
  </h2>
  <span style="font-weight: 400; font-size: 18px; color: #666; font-size: 16px;">
    Vi er et dansk rejsebureau, der specialiserer sig i træningslejre for danske klubber, foreninger og skoler. Hvert år sender vi hundredvis af danske sportsentusiaster til Sydeuropa og resten af verden - hvor den gode oplevelse er et fællestræk ved alle vores rejser. Hos Yak Sport fokuserer vi på:
  </span>
  <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 24px; grid-template-columns: 1fr;">
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <MailBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          God kommunikation med kunden
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Hurtig svartid på email og telefon
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <MoneyBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Gennemsigtige priser
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Hvor alle kan være med uden pludselige ekstra regninger for kunden
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <ReloadBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Fleksibilitet med en fair afbestillingspolitik
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Hvor kunden kan afbestille op til 10% uden at hæfte for det
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <HotelBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Hoteller og træningsfaciliteter
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Det lever op til den høje standard, som vores kunder kræver
        </span>
      </div>
    </div>
  </div>
  <span style="font-size: 18px; color: #666; max-width: 100%; font-size: 16px;">
    En træningsrejse til Sydeuropa ville være det ideelle valg til at kickstarte sæsonen, hvor holdet kun skal fokusere på træningssessioner, personlig udvikling og sammenhold - Og selvfølgelig skal det også være sjovt!
    <br />
    <br />
    Vi sammensætter rejsen efter gruppens specifikke ønsker og behov. Som udgangspunkt indeholder og præsenterer dette tilbud vores standardprodukt, mens muligheden for tilkøb som udflugter er angivet.
    <br />
    <br />
    Vi vil gøre alt for at sikre, at behandlingen og planlægningen fungerer perfekt, og står selvfølgelig altid til rådighed i denne forbindelse.<br />
    <br />
    <br />
    Hvis du har spørgsmål til tilbuddet, er du velkommen til at kontakte os.
    <br />
    Med venlig hilsen<br />
    Ibrahim Oniz<br />
    Grundlægger af Yak Sport
  </span>
</div>
`,
  sv: `<div style="display: flex; flex-direction: column; gap: 28px;">
  <h2 style="font-weight: 600; color: #000; font-size: 30px; margin-bottom: 0; font-size: 26px;">
    Välkommen till Yak Sport
  </h2>
  <span style="font-weight: 400; font-size: 18px; color: #666; font-size: 16px;">
    Vi är en dansk resebyrå som specialiserar sig på träningsläger för danska klubbar, föreningar och skolor. Varje år skickar vi hundratals danska sportentusiaster till Sydeuropa och resten av världen - där den goda upplevelsen är ett gemensamt drag för alla våra resor. På Yak Sport fokuserar vi på:
  </span>
  <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 24px; grid-template-columns: 1fr;">
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <MailBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Bra kommunikation med kunden
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Snabb svarstid på e-post och telefon
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <MoneyBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Transparenta priser
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Där alla kan vara med utan oväntade extrakostnader för kunden
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <ReloadBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Flexibilitet med en rättvis avbokningspolicy
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Där kunden kan avboka upp till 10% utan att vara ansvarig för det
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <HotelBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Hotell och träningsanläggningar
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Det uppfyller den höga standard som våra kunder kräver
        </span>
      </div>
    </div>
  </div>
  <span style="font-size: 18px; color: #666; max-width: 100%; font-size: 16px;">
    En träningsresa till Sydeuropa skulle vara det perfekta valet för att kickstarta säsongen, där laget bara ska fokusera på träningspass, personlig utveckling och gemenskap - Och självklart ska det också vara roligt!
    <br />
    <br />
    Vi sätter ihop resan enligt gruppens specifika önskemål och behov. I grund och botten innehåller och presenterar detta erbjudande vår standardprodukt, medan möjligheten till tillägg som utflykter anges.
    <br />
    <br />
    Vi kommer att göra allt för att säkerställa att behandlingen och planeringen fungerar perfekt, och är naturligtvis alltid tillgängliga i detta avseende.<br />
    <br />
    <br />
    Om du har några frågor om erbjudandet är du välkommen att kontakta oss.
    <br />
    Vänliga hälsningar<br />
    Ibrahim Oniz<br />
    Grundare av Yak Sport
  </span>
</div>
`,
  no: `<div style="display: flex; flex-direction: column; gap: 28px;">
  <h2 style="font-weight: 600; color: #000; font-size: 30px; margin-bottom: 0; font-size: 26px;">
    Velkommen til Yak Sport
  </h2>
  <span style="font-weight: 400; font-size: 18px; color: #666; font-size: 16px;">
    Vi er et dansk reisebyrå som spesialiserer seg på treningsleirer for danske klubber, foreninger og skoler. Hvert år sender vi hundrevis av danske sportsentusiaster til Sør-Europa og resten av verden - hvor den gode opplevelsen er et felles trekk ved alle våre turer. Hos Yak Sport fokuserer vi på:
  </span>
  <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 24px; grid-template-columns: 1fr;">
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <MailBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          God kommunikasjon med kunden
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Rask responstid på e-post og telefon
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <MoneyBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Gennomsiktige priser
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Hvor alle kan delta uten uventede ekstra kostnader for kunden
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <ReloadBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Fleksibilitet med en rettferdig avbestillingspolitikk
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Hvor kunden kan avbestille opptil 10% uten å være ansvarlig for det
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <HotelBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Hoteller og treningsfasiliteter
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Det oppfyller de høye standardene kundene våre krever
        </span>
      </div>
    </div>
  </div>
  <span style="font-size: 18px; color: #666; max-width: 100%; font-size: 16px;">
    En treningsreise til Sør-Europa ville være et ideelt valg for å kickstarte sesongen, hvor laget bare skal fokusere på treningsøkter, personlig utvikling og samhold - Og selvfølgelig skal det også være gøy!
    <br />
    <br />
    Vi setter sammen reisen etter gruppens spesifikke ønsker og behov. Som utgangspunkt inneholder og presenterer dette tilbudet vårt standardprodukt, mens muligheten for tilleggskjøp som utflukter er angitt.
    <br />
    <br />
    Vi vil gjøre alt for å sikre at behandlingen og planleggingen fungerer perfekt, og er selvfølgelig alltid tilgjengelige i denne forbindelse.<br />
    <br />
    <br />
    Hvis du har spørsmål om tilbudet, er du velkommen til å kontakte oss.
    <br />
    Med vennlig hilsen<br />
    Ibrahim Oniz<br />
    Grunnlegger av Yak Sport
  </span>
</div>
`,
  de: `<div style="display: flex; flex-direction: column; gap: 28px;">
  <h2 style="font-weight: 600; color: #000; font-size: 30px; margin-bottom: 0; font-size: 26px;">
    Willkommen bei Yak Sport
  </h2>
  <span style="font-weight: 400; font-size: 18px; color: #666; font-size: 16px;">
    Wir sind ein dänisches Reisebüro, das sich auf Trainingslager für dänische Vereine, Verbände und Schulen spezialisiert hat. Jedes Jahr schicken wir hunderte dänische Sportbegeisterte nach Südeuropa und in den Rest der Welt - wo das gute Erlebnis ein gemeinsames Merkmal all unserer Reisen ist. Bei Yak Sport legen wir Wert auf:
  </span>
  <div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 24px; grid-template-columns: 1fr;">
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <MailBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Gute Kommunikation mit dem Kunden
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Schnelle Reaktionszeit auf E-Mails und Telefon
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <MoneyBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Transparente Preise
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Wo jeder ohne plötzliche zusätzliche Rechnungen für den Kunden dabei sein kann
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <ReloadBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Flexibilität mit einer fairen Stornierungsrichtlinie
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Wo der Kunde bis zu 10% stornieren kann, ohne dafür haftbar zu sein
        </span>
      </div>
    </div>
    <div style="display: flex; align-items: center; gap: 24px; padding: 20px; border: 1px solid #000; border-radius: 12px;">
      <HotelBlue />
      <div style="display: flex; flex-direction: column; gap: 4px;">
        <span style="font-size: 18px; font-weight: 600; color: #000;">
          Hotels und Trainingseinrichtungen
        </span>
        <span style="font-size: 16px; color: #666; font-size: 14px;">
          Es entspricht den hohen Standards, die unsere Kunden verlangen
        </span>
      </div>
    </div>
  </div>
  <span style="font-size: 18px; color: #666; max-width: 100%; font-size: 16px;">
    Eine Trainingsreise nach Südeuropa wäre die ideale Wahl, um die Saison zu starten, bei der sich die Mannschaft nur auf Trainingseinheiten, persönliche Entwicklung und Zusammenhalt konzentrieren sollte - Und natürlich sollte es auch Spaß machen!
    <br />
    <br />
    Wir stellen die Reise nach den spezifischen Wünschen und Bedürfnissen der Gruppe zusammen. Als Ausgangspunkt enthält und präsentiert dieses Angebot unser Standardprodukt, während die Möglichkeit von Zusatzkäufen wie Ausflügen vermerkt ist.
    <br />
    <br />
    Wir werden alles tun, um sicherzustellen, dass die Abwicklung und Planung perfekt funktioniert, und stehen Ihnen diesbezüglich natürlich jederzeit zur Verfügung.<br />
    <br />
    <br />
    Wenn Sie Fragen zum Angebot haben, zögern Sie bitte nicht, uns zu kontaktieren.
    <br />
    Mit freundlichen Grüßen<br />
    Ibrahim Oniz<br />
    Gründer von Yak Sport
  </span>
</div>
`,
};
// export const aboutYakSport=`<h1 class="heading">
// Velkommen til Yak Sport
// </h1>

// <div>
// Vi er et dansk rejsebureau med speciale inden for træningslejre til danske klubber, foreninger og skoler. Hvert år sender vi hundredvis af danske sportsentusiaster til Sydeuropa og resten af verden – hvor den gode oplevelse er fællestræk for alle vores rejser. Hos Yak Sport sætter vi fokus på:
// </div>
// <div>
// - God kommunikation med kunden – Hurtig responstid på mail og telefon.<br>
// - Gennemsigtige priser - Hvor alle kan være med og ingen pludselige ekstra regninger til kunden.<br>
// - Fleksibilitet med fair afbestillingspolitik – Hvor kunden kan afbestille op til 10%, uden at hæfte for det.<br>
// - Hoteller og træningsfaciliteter der lever op til den høje standard vores kunder efterspørger.
// </div>
// <div>
// Et træningsophold til Sydeuropa vil være et ideelt valg til at kickstarte sæsonen, hvor truppen kun skal fokusere på træningspas, personlig udvikling og sammenhold – Og det skal selvfølgelig også være sjovt! Vi sammensætter rejsen efter gruppens specifikke ønsker og behov. Som udgangspunkt indeholder og præsenterer dette tilbud vores standardprodukt, mens mulighed for yderligere tilkøb som udflugter er noteret. Vi vil gøre alt for, at forarbejdet og planlægningen kommer til at fungere perfekt, og står naturligvis altid til rådighed i den forbindelse.
// </div>
// <div>
// Hvis du har spørgsmål til tilbuddet, er du velkommen til at kontakte os.
// </div>
// <div>
// Med venlig hilsen,<br>
// Ibrahim Öniz<br>
// Stifter af Yak Sport<br>
// </div>
// `
// export const leadOfferPrices = {
//   en: ` <div class="d__flex flex__col gap__24">
// <h2 style="font-weight: 600; color: #000; font-size: 30px; margin-bottom: 0; font-size: 26px;">
//   Inkluderede tjenester
// </h2>
// <div style="display: flex; flex-direction: column; align-items: center; gap: 24px; padding: 24px; border: 1px solid #000; border-radius: 12px;">
//   <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
//     <div style="display: flex; align-items: center; gap: 16px;">
//       <TransportBlue />
//       <span style="font-size: 22px; font-weight: 500; color: #000; font-size: 20px;">
//         Transport
//       </span>
//     </div>
//     <ArrowBlack style="width: 18px; height: 18px; cursor: pointer;" />
//   </div>
//   <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 16px; width: 100%;">
//     <div style="display: flex; align-items: center; gap: 12px;">
//       <CheckBoxBlack style="width: 20px; height: 20px;" />
//       <span style="font-size: 16px; color: #666; font-size: 14px;">
//         Fly tur – retur
//       </span>
//     </div>
//     <div style="display: flex; align-items: center; gap: 12px;">
//       <CheckBoxBlack style="width: 18px; height: 18px;" />
//       <span style="font-size: 16px; color: #666; font-size: 14px;">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//   </div>
// </div>

// <div class="d__flex flex__col align__center gap__24 p__24 b__1__black10 radi__12">
//   <div class="w__full d__flex align__center justify__between">
//     <div class="d__flex align__center gap__16">
//       <BedBlue />
//       <span class="fs__22 fw__500 solid__black__80 md-fs__20">
//         Ophold
//       </span>
//     </div>
//     <ArrowBlack class="w__18 h__18 cursor__p" />
//   </div>
//   <div class="d__grid grid__tcol__2 gap__16 w__full sm-grid__tcol__1">
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14 max__w__full">
//         Transport til/fra lufthavnen/hotel Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum perspiciatis voluptatibus adipisci quaerat eius. Animi dolore minus aliquid at eveniet.
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//   </div>
// </div>

// <div class="d__flex flex__col align__center gap__24 p__24 b__1__black10 radi__12">
//   <div class="w__full d__flex align__center justify__between">
//     <div class="d__flex align__center gap__16">
//       <CookingBlue />
//       <span class="fs__22 fw__500 solid__black__80 md-fs__20">
//         Forplejning
//       </span>
//     </div>
//     <ArrowBlack class="w__18 h__18 cursor__p" />
//   </div>
//   <div class="d__grid grid__tcol__2 gap__16 w__full sm-grid__tcol__1">
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14 max__w__full">
//         Transport til/fra lufthavnen/hotel Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum perspiciatis voluptatibus adipisci quaerat eius. Animi dolore minus aliquid at eveniet.
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//   </div>
// </div>

// <div class="d__flex flex__col align__center gap__24 p__24 b__1__black10 radi__12">
//   <div class="w__full d__flex align__center justify__between">
//     <div class="d__flex align__center gap__16">
//       <AtletikBlue class="w__26 h__26" />
//       <span class="fs__22 fw__500 solid__black__80 md-fs__20">
//         Træning
//       </span>
//     </div>
//     <ArrowBlack class="w__18 h__18 cursor__p" />
//   </div>
//   <div class="d__grid grid__tcol__2 gap__16 w__full sm-grid__tcol__1">
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14 max__w__full">
//         Transport til/fra lufthavnen/hotel Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum perspiciatis voluptatibus adipisci quaerat eius. Animi dolore minus aliquid at eveniet.
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//   </div>
// </div>

// <div class="d__flex flex__col align__center gap__24 p__24 b__1__black10 radi__12">
//   <div class="w__full d__flex align__center justify__between">
//     <div class="d__flex align__center gap__16">
//       <ReloadBlue />
//       <span class="fs__22 fw__500 solid__black__80 md-fs__20">
//         Det får du også! Kun hos Yak Sport
//       </span>
//     </div>
//     <ArrowBlack class="w__18 h__18 cursor__p" />
//   </div>
//   <div class="d__grid grid__tcol__2 gap__16 w__full sm-grid__tcol__1">
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14 max__w__full">
//         Transport til/fra lufthavnen/hotel Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum perspiciatis voluptatibus adipisci quaerat eius. Animi dolore minus aliquid at eveniet.
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//   </div>
// </div>

// <div class="d__flex flex__col align__center gap__24 p__24 b__1__black10 radi__12">
//   <div class="w__full d__flex align__center justify__between">
//     <div class="d__flex align__center gap__16">
//       <DashboardBlue class="w__28 h__28" />
//       <span class="fs__22 fw__500 solid__black__80 md-fs__20">
//         Andet
//       </span>
//     </div>
//     <ArrowBlack class="w__18 h__18 cursor__p" />
//   </div>
//   <div class="d__grid grid__tcol__2 gap__16 w__full sm-grid__tcol__1">
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14 max__w__full">
//         Transport til/fra lufthavnen/hotel Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum perspiciatis voluptatibus adipisci quaerat eius. Animi dolore minus aliquid at eveniet.
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//   </div>
// </div>
// </div>`,
//   da: ` <div class="d__flex flex__col gap__24">
// <h2 style="font-weight: 600; color: #000; font-size: 30px; margin-bottom: 0; font-size: 26px;">
//   Inkluderede tjenester
// </h2>
// <div style="display: flex; flex-direction: column; align-items: center; gap: 24px; padding: 24px; border: 1px solid #000; border-radius: 12px;">
//   <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
//     <div style="display: flex; align-items: center; gap: 16px;">
//       <TransportBlue />
//       <span style="font-size: 22px; font-weight: 500; color: #000; font-size: 20px;">
//         Transport
//       </span>
//     </div>
//     <ArrowBlack style="width: 18px; height: 18px; cursor: pointer;" />
//   </div>
//   <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 16px; width: 100%;">
//     <div style="display: flex; align-items: center; gap: 12px;">
//       <CheckBoxBlack style="width: 20px; height: 20px;" />
//       <span style="font-size: 16px; color: #666; font-size: 14px;">
//         Fly tur – retur
//       </span>
//     </div>
//     <div style="display: flex; align-items: center; gap: 12px;">
//       <CheckBoxBlack style="width: 18px; height: 18px;" />
//       <span style="font-size: 16px; color: #666; font-size: 14px;">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//   </div>
// </div>

// <div class="d__flex flex__col align__center gap__24 p__24 b__1__black10 radi__12">
//   <div class="w__full d__flex align__center justify__between">
//     <div class="d__flex align__center gap__16">
//       <BedBlue />
//       <span class="fs__22 fw__500 solid__black__80 md-fs__20">
//         Ophold
//       </span>
//     </div>
//     <ArrowBlack class="w__18 h__18 cursor__p" />
//   </div>
//   <div class="d__grid grid__tcol__2 gap__16 w__full sm-grid__tcol__1">
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14 max__w__full">
//         Transport til/fra lufthavnen/hotel Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum perspiciatis voluptatibus adipisci quaerat eius. Animi dolore minus aliquid at eveniet.
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//   </div>
// </div>

// <div class="d__flex flex__col align__center gap__24 p__24 b__1__black10 radi__12">
//   <div class="w__full d__flex align__center justify__between">
//     <div class="d__flex align__center gap__16">
//       <CookingBlue />
//       <span class="fs__22 fw__500 solid__black__80 md-fs__20">
//         Forplejning
//       </span>
//     </div>
//     <ArrowBlack class="w__18 h__18 cursor__p" />
//   </div>
//   <div class="d__grid grid__tcol__2 gap__16 w__full sm-grid__tcol__1">
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14 max__w__full">
//         Transport til/fra lufthavnen/hotel Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum perspiciatis voluptatibus adipisci quaerat eius. Animi dolore minus aliquid at eveniet.
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//   </div>
// </div>

// <div class="d__flex flex__col align__center gap__24 p__24 b__1__black10 radi__12">
//   <div class="w__full d__flex align__center justify__between">
//     <div class="d__flex align__center gap__16">
//       <AtletikBlue class="w__26 h__26" />
//       <span class="fs__22 fw__500 solid__black__80 md-fs__20">
//         Træning
//       </span>
//     </div>
//     <ArrowBlack class="w__18 h__18 cursor__p" />
//   </div>
//   <div class="d__grid grid__tcol__2 gap__16 w__full sm-grid__tcol__1">
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14 max__w__full">
//         Transport til/fra lufthavnen/hotel Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum perspiciatis voluptatibus adipisci quaerat eius. Animi dolore minus aliquid at eveniet.
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//   </div>
// </div>

// <div class="d__flex flex__col align__center gap__24 p__24 b__1__black10 radi__12">
//   <div class="w__full d__flex align__center justify__between">
//     <div class="d__flex align__center gap__16">
//       <ReloadBlue />
//       <span class="fs__22 fw__500 solid__black__80 md-fs__20">
//         Det får du også! Kun hos Yak Sport
//       </span>
//     </div>
//     <ArrowBlack class="w__18 h__18 cursor__p" />
//   </div>
//   <div class="d__grid grid__tcol__2 gap__16 w__full sm-grid__tcol__1">
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14 max__w__full">
//         Transport til/fra lufthavnen/hotel Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum perspiciatis voluptatibus adipisci quaerat eius. Animi dolore minus aliquid at eveniet.
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//   </div>
// </div>

// <div class="d__flex flex__col align__center gap__24 p__24 b__1__black10 radi__12">
//   <div class="w__full d__flex align__center justify__between">
//     <div class="d__flex align__center gap__16">
//       <DashboardBlue class="w__28 h__28" />
//       <span class="fs__22 fw__500 solid__black__80 md-fs__20">
//         Andet
//       </span>
//     </div>
//     <ArrowBlack class="w__18 h__18 cursor__p" />
//   </div>
//   <div class="d__grid grid__tcol__2 gap__16 w__full sm-grid__tcol__1">
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14 max__w__full">
//         Transport til/fra lufthavnen/hotel Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum perspiciatis voluptatibus adipisci quaerat eius. Animi dolore minus aliquid at eveniet.
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__20 h__20" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Fly tur – retur
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//     <div class="d__flex align__center gap__12">
//       <CheckBoxBlack class="w__18 h__18" />
//       <span class="fs__16 solid__black__60 md-fs__14">
//         Transport til/fra lufthavnen/hotel
//       </span>
//     </div>
//   </div>
// </div>
// </div>`,
// };
// export const leadOfferPrices=`<h1 class="heading">Inkluderede tjenester</h1>

// <div class="service__section">

//     <p class="title">Transport</p>

//     <li>- Fly tur – retur</li>
//     <li>- Transport til/fra lufthavnen/hotel</li>

//     <p class="title">Ophold:</p>

//     <li>- Overnatning på 5 stjernet hotel med All Inklusive koncept </li>
//     <li>- Styrketræningslokale på hotel </li>
//     <li>- Opbevaringsrum til tøj, bolde m.m. </li>
//     <li>- Mødelokale på hotel </li>
//     <li>- Værelse med massagebriks til massøren </li>
//     <li>- Frit brug af swimmingpool </li>
//     <li>- Minibar på værelserne og opfyldning af vand </li>
//     <li>- Mulighed for at opgradering af VIP værelse til cheftræner (SUIT) </li>
//     <li>- Mulighed for hele holdet bor på samme etage </li>

//     <p class="title">Forplejning</p>

//     <li>- Fuld forplejning med morgenmad, frokost og aftensmad inkl. drikkevarer * Bemærk: øl/vin kun tilladt til
//         træner/leder</li>
//     <li>- Te, kaffe-buffet hele dagen</li>
//     <li>- Frugt ved alle måltider</li>
//     <li>- Særskilt reserveret bord ved alle måltider * Morgenmadsbuffet, sen morgenmadsbuffet, frokostbuffet,
//         aftensmadsbuffet.</li>

//     <p class="title">Træning</p>

//     <li>- 2 x daglig træning af 90 min</li>
//     <li>- 2 x dagligt vask af træningstøj</li>
//     <li>- Mulighed for træningskampe ekskl. dommerudgifter</li>
//     <li>- Leje af baner, bane inkl. i prisen</li>
//     <li>- Kildevand under træning og kamp</li>

//     <p class="title">Det får du også! Kun hos Yak Sport</p>

//     <li>- Gratis afbestillingspolitik frem til 60 dage inden afrejse – Maks. 10% af pladserne</li>
//     <li>- Fuld refusion indenfor 14 dage, såfremt Udenrigsministeriet fraråder rejsen</li>
//     <li>- Øget hygiejne- og rengøringsforanstaltninger for at formindske risikoen for sygdomme</li>
//     <li>- Check-ind assistance ved Antalya Lufthavn - Vi hjælper med at i kommer trygt igennem check-in</li>
//     <li>- Rejselederservice som står til rådighed 24 timer i døgnet under hele rejsen</li>
//     <li>- Gratis hjælp og rådgivning til e-visa ved ikke-danske statsborgere (dansk pas behøves ikke visum)</li>

//     <p class="title">Andet</p>
//     <li>- Bidrag til Rejsegarantifonden (reg. nr. 2848), olietillæg og lufthavnsafgift</li>

// </div>`
