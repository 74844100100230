import {
  Box,
  Button,
  TextField,
  Fab,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

// import {
//   selectedPageSelector,
//   setSelectedPage,

// } from "app/rkt_query/SelectedStuffReducer";

import {
  selectedFaqsSelector,
  setSelectedFaqs,
} from "app/rkt_query/SelectedStuffReducer";

import {
  LANGUAGES,
  selectedLanguageSelector,
} from "app/rkt_query/SettingsReducer";

// import {
//   BLUE_PRINTS,
//   useAddPageMutation,
//   useGetPageQuery,
//   useUpdatePageMutation,
// } from "app/rkt_query/storeApis";

import {
  BLUE_PRINTS,
  useAddFaqsMutation,
  useGetFaqsQuery,
  useUpdateFaqsMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField from "../../../@jumbo/dz-components/textboxes/DZTextField";

import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";

import Grid from "@mui/material/Grid";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import { Config } from "constant";
import DZLabel from "@jumbo/dz-components/labels/DZLabel";

import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";

import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";

import DzTextEditorMultilingual from "@jumbo/dz-components/TextEditor/DzTextEditorMultilingual";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const textContent =
  "<h2>Awesome Rich Content</h2>\n" +
  "<p>Suspendisse id sollicitudin dui. <strong>Vestibulum eu sapien pharetra,</strong> bibendum ligula id, ullamcorper ligula.</p>\n" +
  "\n" +
  "<ul>\n" +
  "        <li>ullamcorper ligula</li>\n" +
  "        <li>Duis vel neque</li>\n" +
  "</ul>\n" +
  "\n" +
  "<p><em>Sed feugiat hendrerit risus, quis efficitur massa facilisis vitae. Aliquam erat volutpat. </em></p>\n";
const AddUpdateFaqs = ({ onClose, learning_journey_id, selectedId }) => {
  // const chatbot_types = ['scale', 'note', 'buddy'];
  const { showDialog, hideDialog } = useJumboDialog();

  const dispatch = useDispatch();

  // const selectedPage = useSelector(selectedPageSelector);
  const selectedFaqs = useSelector(selectedFaqsSelector);

  // const {
  //   data: res_get,
  //   isLoading: isLoadingPage,
  //   error: errorPage,
  // } = useGetPageQuery(selectedPage?.id);

  // // console.log("ghfffhgh", res_get)
  // const [updatePage, responseUpdate] = useUpdatePageMutation();
  // const [addPage, responseAdd] = useAddPageMutation();
  const { fnShowSnackBar } = useSnackBarManager();

  const {
    data: res_get,
    isLoading: isLoadingFaqs,
    error: errorPage,
  } = useGetFaqsQuery(selectedFaqs?.id);
  const [updateFaqs, responseUpdate] = useUpdateFaqsMutation();
  const [addFaqs, responseAdd] = useAddFaqsMutation();

  //const bluePrint = BLUE_PRINTS.page;
  const bluePrint = BLUE_PRINTS.faqs;
  // const bluePrintEngagement = BLUE_PRINTS.engagement;
  // console.log(bluePrintEngagement);

  // const [pageState, setPageState] = useState(null);
  const [faqsState, setFaqsState] = useState(null);

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [editorState, setEditorState] = useState(selectedLanguage);

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    setFaqsState(
      selectedFaqs
        ? res_get?.data
        : {
            ...bluePrint,
          }
    );
  }, [res_get]);

  const addUpdateFaqsData = async (action) => {
    const formData = new FormData();

    console.log(res_get?.data?.id);
    console.log(faqsState.question);
    console.log(faqsState.answer);

    formData.append("id", res_get?.data?.id);
    formData.append("question", JSON.stringify(faqsState.question));
    formData.append("answer", JSON.stringify(faqsState.answer));

    const res =
      action === "add" ? await addFaqs(formData) : await updateFaqs(formData);

    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
  };

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {faqsState ? (
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: 60,
          }}
        >
          <Grid container spacing={3.75}>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <MultiDZTextValidation
                  error={error?.[0]}
                  label="Question"
                  variant="outlined"
                  value={faqsState?.question?.[selectedLanguage] || ""}
                  onChange={(e) => {
                    console.log("Input Value:", selectedLanguage);
                    setFaqsState((x) => ({
                      ...x,
                      question: {
                        ...x.question,
                        [selectedLanguage]: e.target.value,
                      },
                    }));
                  }}
                />
              </Div>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <MultiDZTextValidation
                  error={error?.[0]}
                  label="Answer"
                  variant="outlined"
                  noOfRows={10}
                  maxRows={10}
                  value={faqsState?.answer?.[selectedLanguage]}
                  onChange={(e) => {
                    console.log("Input Value:", selectedLanguage);
                    setFaqsState((x) => ({
                      ...x,
                      answer: {
                        ...x.answer,
                        [selectedLanguage]: e.target.value,
                      },
                    }));
                  }}
                />
              </Div>
            </Grid>
          </Grid>

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                dispatch(setSelectedFaqs(null));
              }}
            >
              Cancel
            </Button>
            {res_get?.data?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateFaqsData();
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedFaqs(null));
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateFaqsData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedFaqs(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
          {/* dispatch(setSelectedPage(null)); */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateFaqs;
