import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import {
  selectedLeadOfferFeedbackSelector,
  setSelectedLeadOfferFeedback,
} from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddLeadOfferFeedbackMutation,

  useGetLeadOfferFeedbackQuery,
  useUpdateLeadOfferFeedbackMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar, {
  ErrorSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";

import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import Div from "@jumbo/shared/Div";
const AddUpdateLeadOfferFeedback = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedLeadOfferFeedback = useSelector(selectedLeadOfferFeedbackSelector);
  const { data: res_get } = useGetLeadOfferFeedbackQuery(selectedLeadOfferFeedback?.id);

  const [updateLeadOfferFeedback, responseUpdate] = useUpdateLeadOfferFeedbackMutation();
  const [addLeadOfferFeedback, responseAdd] = useAddLeadOfferFeedbackMutation();
  const bluePrint = BLUE_PRINTS.leadOfferFeedback;
  const [LeadOfferFeedbackState, setLeadOfferFeedbackState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [errors, setErrors] = useState({});
  const [openError, setOpenError] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();

  const addUpdateLeadOfferFeedbackData = async (action) => {
    const newErrors = validateHotelData();
    setErrors(newErrors);
    if (!isEmpty(newErrors)) {
      setOpenError(true);
      return;
    }
    const formData = new FormData();
    formData.append("id", res_get?.data?.lead_offer_feedback?.id);
    formData.append("title", JSON.stringify(LeadOfferFeedbackState.title));
    formData.append("text_field", LeadOfferFeedbackState.text_field==true?1:0);

    const res =
      action === "add"
        ? await addLeadOfferFeedback(formData)
        : await updateLeadOfferFeedback(formData);
    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
  };
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);
  const regionIds = res_get?.data?.regions?.map((region) =>
    parseInt(region?.region_id, 10)
  );

  useEffect(() => {
    setLeadOfferFeedbackState(
      selectedLeadOfferFeedback
        ? { ...res_get?.data?.lead_offer_feedback,}
        : bluePrint
    );
  }, [res_get]);


  function isEmpty(value) {
    if (value === null || value === undefined) {
      return true;
    }
    if (typeof value === "string" && value.trim() === "") {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === "object" && Object.keys(value).length === 0) {
      return true;
    }
    return false;
  }
  const validateHotelData = () => {
    let newErrors = {};
    if (isEmpty(LeadOfferFeedbackState?.title)) {
      newErrors = { ...newErrors, name: "Title Field Required" };
    }

    return newErrors;
  };
  function getFirstErrorMessage(errors) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        return errors[key];
      }
    }
    return "No errors found";
  }
  return (
    <div>
      {openError && (
        <ErrorSnackbar
          error={getFirstErrorMessage(errors)}
          setOpenError={setOpenError}
        />
      )}
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />
      {LeadOfferFeedbackState ? (
        <div style={addUpdateDivStyleGlobal}>
          <MultiDZTextValidation
            error={error?.[0]}
            label="Title"
            variant="outlined"
            value={LeadOfferFeedbackState?.title?.[selectedLanguage]}
            onChange={(e) => {
              setLeadOfferFeedbackState((x) => ({
                ...x,
                title: { ...x.title, [selectedLanguage]: e.target.value },
              }));
            }}
            placeholder="Name"
          />
        <Grid item xs={4} md={4} lg={4}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
          <FormControlLabel control={<Checkbox checked={LeadOfferFeedbackState?.text_field==0?false:true} onChange={(e) => { setLeadOfferFeedbackState(x => ({ ...x, text_field: e.target.checked })) }}  />}   label="Is TextField"   />
          </Div>
          </Grid>
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedLeadOfferFeedback(null));
              }}
            >
              Cancel
            </Button>
            {selectedLeadOfferFeedback?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadOfferFeedbackData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadOfferFeedbackData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedLeadOfferFeedback(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateLeadOfferFeedback;
