import {
  Box,
  Button,
  TextField,
  Fab,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  selectedAirportSelector,
  setSelectedAirport,
} from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddAirportMutation,
  useGetAirportQuery,
  useUpdateAirportMutation,
  useGetAllRegionsQuery,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";

import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  ErrorSnackbar,
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Grid from "@mui/material/Grid";
import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { DzNoBackgroundLabel } from "@jumbo/dz-components/labels/DZLabel";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

// import { AddCircle } from "@material-ui/icons";

const AddUpdateAirport = () => {
  const { hideDialog } = useJumboDialog();
  const { fnShowSnackBar } = useSnackBarManager();
  const dispatch = useDispatch();
  const selectedAirport = useSelector(selectedAirportSelector);
  const { data: res_get_all } = useGetAllRegionsQuery();
  const { data: res_get } = useGetAirportQuery(selectedAirport?.id);
  const [updateAirport, responseUpdate] = useUpdateAirportMutation();
  const [addAirport, responseAdd] = useAddAirportMutation();
  const bluePrint = BLUE_PRINTS.airport;
  const [airportState, setAirportState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [errors, setErrors] = useState({});
  const [openError, setOpenError] = useState(false);
  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);
  const regionIds = res_get?.data?.regions?.map((region) =>
    parseInt(region?.region_id, 10)
  );

  useEffect(() => {
    setAirportState(
      selectedAirport
        ? { ...res_get?.data?.region_airports, regions_id: regionIds ?? [] }
        : bluePrint
    );
    
    // setAirportState(
    //   selectedAirport
    //     ? res_get?.data?.region_airports
    //     : {
    //         ...bluePrint,
    //         region_id: res_get?.data?.region_airports?.region_id ?? "",

    //       }
    // );
  }, [res_get]);

  const addUpdateAirportData = async (action) => {
    const newErrors = validateHotelData();
    setErrors(newErrors);
    if (!isEmpty(newErrors)) {
      setOpenError(true);
      return;
    }
    const formData = new FormData();
    formData.append("id", res_get?.data?.region_airports?.id);
    formData.append("name", JSON.stringify(airportState.name));
   
    formData.append("regions_id", JSON.stringify(airportState.regions_id));
    const res = action === "add" ? await addAirport(formData) : await updateAirport(formData);
    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message|| "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message, "error");
    }
  };
  const regions = res_get_all?.data?.regions?.map((region) => region);

  function isEmpty(value) {
    if (value === null || value === undefined) {
      return true;
    }
    if (typeof value === "string" && value.trim() === "") {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === "object" && Object.keys(value).length === 0) {
      return true;
    }
    return false;
  }
  const validateHotelData = () => {
    let newErrors = {};
    if (isEmpty(airportState?.regions_id)) {
      newErrors = { ...newErrors, name: "Please select the Region" };
    }
    return newErrors;
  };
  function getFirstErrorMessage(errors) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        return errors[key];
      }
    }
    return "No errors found";
  }
  
  return (
    <div>
      {openError && (
        <ErrorSnackbar
          error={getFirstErrorMessage(errors)}
          setOpenError={setOpenError}
        />
      )}
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />
      {airportState ? (
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: 60,
          }}
        >
          {/* <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZDropdown
                fieldTextName="language"
                  error={error?.region_id}
                  value={airportState?.region_id}
                  data={regions}
                  onChange={(e) => {
                    setAirportState((x) => ({
                      ...x,
                      region_id: e.target.value,
                    }));
                  }}
                  placeholder="Select Region"
                />
              </Div>
            </Grid> */}

          <Grid container spacing={3.75}>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZTextValidation
                  error={error?.name}
                  label="Title"
                  variant="outlined"
                  value={airportState?.name?.[selectedLanguage]}
              onChange={(e) => {
                setAirportState((x) => ({
                      ...x,
                      name: { ...x.name, [selectedLanguage]: e.target.value },
                    }));
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DzNoBackgroundLabel title={"Regions"} />
              </Div>
            </Grid>
            {regions?.map((region) => (
              <Grid item xs={5} md={2} lg={2} key={region?.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={airportState?.regions_id?.includes(region?.id)}
                      name={region?.language}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setAirportState((prevState) => ({
                          ...prevState,
                          regions_id: checked
                            ? [...prevState?.regions_id, region?.id]
                            : prevState?.regions_id.filter(
                                (id) => id !== region?.id
                              ),
                        }));
                      }}
                    />
                  }
                  label={region?.language}
                />
              </Grid>
            ))}
          </Grid>
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                dispatch(setSelectedAirport(null));
              }}
            >
              Cancel
            </Button>
            {res_get?.data?.region_airports?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateAirportData();
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedAirport(null));
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateAirportData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedAirport(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
          {/* dispatch(setSelectedAirport(null)); */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateAirport;
