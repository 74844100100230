import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import { Button, TextField, Fab, Box } from "@mui/material";
import {
  selectedSportSelector,
  setSelectedSport,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddSportMutation,
  useGetSportQuery,
  useUpdateSportMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateSport = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedSport = useSelector(selectedSportSelector);
  const {
    data: res_get,
    isLoading: isLoadingSport,
    error: errorSport,
  } = useGetSportQuery(selectedSport?.id);
  const { fnShowSnackBar } = useSnackBarManager();

  const [updateSport, responseUpdate] = useUpdateSportMutation();
  const [addSport, responseAdd] = useAddSportMutation();
  const bluePrint = BLUE_PRINTS.sport;

  const [disable, setDisabled] = useState([true]);

  const [sportState, setSportState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();
  const [showBackDrop, setShowBackDrop] = useState(false);
  // const [error, setError] = useState([]);
  // const [count, setCount] = useState(true);

  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState(res_get?.data?.sport?.image);

  const addUpdateSportData = async (action) => {
    const formData = new FormData();
    // formData.append('image', selectedFile);
    formData.append("id", res_get?.data?.sport?.id);
    formData.append("name", JSON.stringify(sportState.name));
    const res =
      action === "add" ? await addSport(formData) : await updateSport(formData);
    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
  };

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);
  // if (responseAdd?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseAdd?.error?.data?.errors)
  // }

  // if (responseUpdate?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseUpdate?.error?.data?.errors)
  // }

  // const countZero = () => {
  //   setCount(true)
  // }
  // console.log(responseUpdate?.error?.data?.errors)
  // if (responseAdd?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseAdd?.error?.data?.errors)
  // }

  // if (responseUpdate?.error?.data?.errors && count) {
  //   setCount(false)
  //   setError(responseUpdate?.error?.data?.errors)
  // }

  useEffect(() => {
    res_get?.data?.sport?.image && setSelectedFile(res_get?.data?.sport?.image);

    // setCompanyId(res_get1?.data?.company?.id)
    setSportState(selectedSport ? res_get?.data?.sport : bluePrint);
    // setInputFields(modules)
    // setSportState(...courseState?.company_id && )
    setDisabled(selectedSport ? false : true);

    // if (res_get1?.data?.company?.id) {
    //   //dispatch(setSelectedSport(null));
    //   console.log("Checking")
    // }
  }, [res_get]);

  console.log("update response", sportState);
  // const companies = res_get_all?.data?.companies?.map((company) => (company))
  // const callCompanyTeams = () => {
  //   setCompanyId(courseState?.company_id)
  // }
  // console.log(companies, "companies")
  // console.log('comp', companies)
  // console.log('transformed result :: ', res_get1?.data?.company?.name)
  // console.log('Disable :: ', disable)
  const filehandle = (e) => {
    if (e.target.files.length !== 0) {
      setSelectedFile(e.target.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // const handleFormChange = (index, event) => {
  //   console.log('event: ', event.target.value);
  //   setInputFields(inputFields?.map((eng, i) => i === index ? {
  //     ...eng,
  //     [event.target.name]: (
  //       event.target.value)
  //   } : eng))
  // const values = [...inputFields];
  // values[index].value = event.target.value;
  // setInputFields(values);

  //console.log('event target value: ', event.target.value);
  //document.getElementsByName("reply_exercise_id")''
  // setInputFields(inputFields.map((eng, i) => i === index ? {
  //   ...eng,
  //   [event.target.name]: (
  //     event.target.value
  //       : { ...eng?.[event.target.name], [selectedLanguage]: event.target.value }
  // )
  // } : eng));

  // }
  // console.warn(inputFields);
  // const company_teams = res_get_all_team?.data?.teams?.map((team) => (team));

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {sportState ? (
        <div style={addUpdateDivStyleGlobal}>
          {/* image start */}
          {/* {imagePreview ? (
            <img src={imagePreview} style={{ height: 100, width: 100, borderRadius: 50, alignSelf: "center" }} />
          ) : (

            <DZImageCircle image={selectedFile} link={Config.digitalOceanLinkSportSmallImg} style={{
              height: 100, width: 100, borderRadius: 50, alignSelf: "center",
            }}
            />

          )}

          <Box sx={{ display: 'flex', paddingBottom: '0px', justifyContent: 'center', paddingTop: '0px' }}>
            <label htmlFor="upload-photo">
              <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" onChange={filehandle}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>

            </label>
          </Box> */}
          {/* <DZDropdown error={error?.company_id} placeholder={'Select Company'} value={courseState?.company_id} data={companies}
            onChange={(e) => { setSportState(x => ({ ...x, company_id: e.target.value, })); callCompanyTeams(); }} /> */}
          {/* 
          <DZSimpleDropDown value={courseState?.order_type} data={order_type} name="reply_type"
            placeholder="Select Order Type" onChange={(e) => { setSportState(x => ({ ...x, order_type: e.target.value, })) }} />

          <DZDropdown placeholder={'Select Team'} value={courseState?.team_id} data={company_teams}
            onChange={(e) => { setSportState(x => ({ ...x, team_id: e.target.value, })) }} /> */}

          <MultiDZTextValidation
            error={error?.[0] && "The Field is required"}
            label="Name"
            variant="outlined"
            value={sportState?.name[selectedLanguage] || ""}
            onChange={(e) => {
              setSportState((x) => ({
                ...x,
                name: { ...x.name, [selectedLanguage]: e.target.value },
              }));
            }}
            placeholder="Name"
          />

          {/* <TextField label="Start Date" type="date" variant="outlined" value={courseState?.date}
            onChange={(e) => { setSportState(x => ({ ...x, date: e.target.value })) }}

          /> */}
          {/* {
            (courseState?.order_type === "order_sequence" && inputFields) ? <form>
              <Card sx={{ overflow: 'visible', mt: 4, border: 1 }}>
                <CardContent>
                  {inputFields?.map((input, index) => {
                    return (

                      <div key={index} >

                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>
                            <DzNoBackgroundTranslatableModule children={input?.name} />
                          </Div>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                          <Div sx={{ mb: 0.5, mt: 1 }}>

                            <input type="hidden" name='id' value={input?.id} onChange={event => handleFormChange(index, event)} />

                            <TextField fullWidth label="Start Time" type='date' name='start_datetime' variant="outlined" noOfRows={1} value={input.start_datetime}
                              onChange={event => handleFormChange(index, event)}
                            />
                          </Div>
                        </Grid>


                      </div>

                    )
                  })}
                </CardContent>
              </Card>
            </form> : ''

          } */}
          {/* <DZTextField label="Description" variant="outlined" noOfRows={3} value={courseState?.description[selectedLanguage]}
            onChange={(e) => { setSportState(x => ({ ...x, description: { ...x.description, [selectedLanguage]: e.target.value } })) }}
          /> */}
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedSport(null));
              }}
            >
              Cancel
            </Button>
            {selectedSport?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateSportData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateSportData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedSport(null));
                }}
              >
                Add
              </Button>
            )}
            {/* dispatch(setSelectedSport(null)); */}
          </div>
          {/* <div style={{ width: '10%' }}>{JSON.stringify(courseState)}</div> */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
      {/* {!selectedSport &&
        (responseAdd.isLoading?<div>Loading.....</div>
        : responseAdd.isError?<div>{JSON.stringify(responseAdd.error)}</div>
        : responseAdd.isSuccess?<div>{JSON.stringify(responseAdd.data)}.</div>
        : <div>Click Add Button .....</div>)
      } */}
    </div>
  );
};

export default AddUpdateSport;
