import { Box, Button, TextField, Fab } from "@mui/material";
import {
  selectedPopularHotelSelector,
  setSelectedPopularHotel,
} from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddPopularHotelMutation,
  useGetPopularHotelQuery,
  useUpdatePopularHotelMutation,
  useGetAllCountriesQuery,
  useGetAllPopularHotelsQuery,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField from "../../../@jumbo/dz-components/textboxes/DZTextField";
import ComboBox from "../../../@jumbo/dz-components/autocomplete-cb/ComboBox";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Grid from "@mui/material/Grid";

import Div from "@jumbo/shared/Div";
import { dialogButtonsStyle } from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

// import { AddCircle } from "@material-ui/icons";

const AddUpdatePopularHotel = () => {
  // const chatbot_types = ['scale', 'note', 'buddy'];
  const { showDialog, hideDialog } = useJumboDialog();

  const dispatch = useDispatch();
  const { fnShowSnackBar } = useSnackBarManager();

  const selectedPopularHotel = useSelector(selectedPopularHotelSelector);

  const { data: res_get_all } = useGetAllPopularHotelsQuery();

  const { data: res_get } = useGetPopularHotelQuery(selectedPopularHotel?.id);

  const [updatePopularHotel, responseUpdate] = useUpdatePopularHotelMutation();
  const [addPopularHotel, responseAdd] = useAddPopularHotelMutation();

  const bluePrint = BLUE_PRINTS.popularHotel;
  const ratingOptions = [0,1,2,3,4,5,6,7,8,9,10]

  const [popularHotelState, setPopularHotelState] = useState(null);

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);
  // const [sports, setFilteredSports] = useState([]);
  // const [regions, setFilteredRegions] = useState([]);
  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  // const sports = res_get_all?.data?.hotels?.hotel_sports?.map((sport) =>
  //   BLUE_PRINTS.sport.translate(sport, selectedLanguage)
  // );

  // const regions = res_get_all?.data?.regions?.map((region) => region );
  const hotels = res_get_all?.data?.hotels?.map((hotel) => hotel);
  // const handleHotelChange = (e) => {
  //   const selectedHotelId = e.target.value;
  //   setPopularHotelState((prevState) => ({
  //     ...prevState,
  //     hotel_id: selectedHotelId,
  //   }));

  //   // Filter sports for the selected hotel
  
  // };

  useEffect(() => {
    setPopularHotelState(
      selectedPopularHotel
        ? res_get?.data?.popular_hotel
        : {
            ...bluePrint,
            country_id: res_get?.data?.popular_hotel?.country_id ?? "",
          }
    );
  }, [res_get]);
  const selectedHotel = hotels.find((hotel) => hotel.id === popularHotelState?.hotel_id);
    const sports =
      selectedHotel?.hotel_sports.map((hs) =>
        BLUE_PRINTS.sport.translate(hs.sport, selectedLanguage)
      ) || [];
    const regions =
      selectedHotel?.region_hotels.map((hs) => hs.region) || [];
    // setFilteredSports(relatedSports);
    // setFilteredRegions(relatedRegions);
  const addUpdatePopularHotelData = async (action) => {
    const formData = new FormData();
    formData.append("id", res_get?.data?.popular_hotel?.id);

   

    formData.append("hotel_id", popularHotelState.hotel_id);
    formData.append("sport_id", popularHotelState.sport_id);
    formData.append("region_id", popularHotelState.region_id);
    formData.append("popular_rating", popularHotelState.popular_rating);

    const res =
      action === "add"
        ? await addPopularHotel(formData)
        : await updatePopularHotel(formData);
    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message, "error");
    }
  };
  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />
      {popularHotelState ? (
        <div
          style={{
            marginTop: 25,
            display: "flex",
            flexDirection: "column",
            gap: 25,
            marginBottom: 60,
          }}
        >
          <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZDropdown
                error={error?.hotel_id}
                value={popularHotelState?.hotel_id || ""}
                data={hotels}
                onChange={(e) => {
                  setPopularHotelState((x) => ({
                    ...x,
                    hotel_id: e.target.value,
                  }));
                }}
                placeholder="Select Hotel"
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZDropdown
                error={error?.sport_id}
                value={popularHotelState?.sport_id || ""}
                data={sports}
                onChange={(e) => {
                  setPopularHotelState((x) => ({
                    ...x,
                    sport_id: e.target.value,
                  }));
                }}
                placeholder="Select Sport"
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZDropdown
                fieldTextName="title"
                error={error?.region_id}
                value={popularHotelState?.region_id || ""}
                data={regions}
                onChange={(e) => {
                  setPopularHotelState((x) => ({
                    ...x,
                    region_id: e.target.value,
                  }));
                }}
                placeholder="Select Region"
              />
            </Div>
          </Grid>

          <Grid item xs={12} md={3} lg={3}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZSimpleDropDown
                error={error?.popular_rating}
                value={popularHotelState?.popular_rating || ""}
                data={ratingOptions}
                onChange={(e) => {
                  setPopularHotelState((x) => ({
                    ...x,
                    popular_rating: e.target.value,
                  }));
                }}
                placeholder="Select Rating"
              />
            </Div>
          </Grid>

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                dispatch(setSelectedPopularHotel(null));
              }}
            >
              Cancel
            </Button>
            {res_get?.data?.popular_hotel?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdatePopularHotelData();
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedPopularHotel(null));
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdatePopularHotelData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedPopularHotel(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
          {/* dispatch(setSelectedPopularHotel(null)); */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdatePopularHotel;
