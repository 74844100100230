
import { selectedAirportSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useGetAirportQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
const ViewAirport = ({ selectId }) => {

    const selectedAirport = useSelector(selectedAirportSelector);
    const [AirportId, setAirportId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingAirport, error: errorAirport } = useGetAirportQuery(AirportId);
    // const ViewAirport = ({showId}) => {
  
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
   
        setAirportId(selectedAirport ? selectedAirport?.id : selectId)
    }, [])
    const airportArray =
        [
            { label: 'Name', data: res_get?.data?.region_airports?.name?.[selectedLanguage] },
            { label: 'Region', data: res_get?.data?.region_airports?.region?.name },
       

        ]

    return (
        <div>

            {errorAirport ? <div>{JSON.stringify(errorAirport)}</div>
                : isLoadingAirport ? <div>Loading Single Airport.....</div>
                    : res_get?.data?.region_airports ?
                        <DZGenericView obj={airportArray}  closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewAirport

