import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import {
  Button,
  TextField,
  Fab,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  List,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  InputAdornment,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import {
  selectedLeadOfferSelector,
  setSelectedLeadOffer,
  selectedCompanySelector,
  setSelectedCompany,
  selectedLeadSelector,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddLeadOfferMutation,
  useGetLeadOfferQuery,
  useUpdateLeadOfferMutation,
  useGetAllLeadOffersQuery,
  useGetAllSportsQuery,
  useGetAllCountriesQuery,
  useGetAllCitiesQuery,
  useGetAllLeadOfferUsersQuery,
  useGetAllAirportsQuery,
  useGetAllHotelsQuery,
  useFetchHotelPricesByAirportMutation,
  useGetAllLeadServicesQuery,
  useDeleteLeadOfferPricesMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  ErrorSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import DZLabel, {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";

import DZDeleteDialog from "@jumbo/dz-components/dialog/DZDeleteDialog";
import DeleteIcon from "@mui/icons-material/Delete";

import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import DzTextEditorMultilingual from "@jumbo/dz-components/TextEditor/DzTextEditorMultilingual";
import { aboutYakSport } from "./InitialDataLeadOffer";
import { data } from "@jumbo/dz-components/SalesStatistics/data";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateLeadOffer = ({ onClose, lead_id }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [openError, setOpenError] = useState(false);
  const selectedLeadOffer = useSelector(selectedLeadOfferSelector);

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const lead_offer_status = ["draft", "send", "success", "rejected", "deleted"];
  const { data: res_get, isSuccess : isSuccessResGet, refetch : refetchGetLeadOffer } = useGetLeadOfferQuery(selectedLeadOffer && selectedLeadOffer?.id);
  const {
    data: leadRequest,
    isLoading: isLoadingLead,
    error: errorLead,
  } = useGetAllLeadOffersQuery(lead_id);

  const [getAirportWithPrice] = useFetchHotelPricesByAirportMutation();

  const [updateLeadOffer, responseUpdate] = useUpdateLeadOfferMutation();
  const [addLeadOffer, responseAdd] = useAddLeadOfferMutation();
  const [deleteLeadOfferPrices] = useDeleteLeadOfferPricesMutation();
  const { data: use_get_all_countries } = useGetAllCountriesQuery();
  const { data: use_get_all_cities } = useGetAllCitiesQuery();
  const bluePrint = BLUE_PRINTS.leadOffer;

  const { data: res_get_all_airports } = useGetAllAirportsQuery();
  const { data: res_get_all_hotels } = useGetAllHotelsQuery();
  const { data: res_get_all_lead_services } = useGetAllLeadServicesQuery();
  const { fnShowSnackBar } = useSnackBarManager();

  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [leadOfferState, setLeadOfferState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [index1, setIndex] = React.useState(null);
  const [flightPrice, setFlightPrice] = React.useState(null);
  const [inputFields, setInputFields] = useState([
    { id: "", label: "", price: 0 },
  ]);
  const [hotelAirport, setHotelAirport] = useState([]);

  const leadData = res_get_all_lead_services?.data?.lead_services ?? [];

  const handleFromDateChange = (fromValue) => {
    const selectedDate = dayjs(fromValue).format("YYYY-MM-DD");
    setLeadOfferState({
      ...leadOfferState,
      from_date: selectedDate,
    });
  };

  const handleToDateChange = (toValue) => {
    const selectedDate = dayjs(toValue).format("YYYY-MM-DD");
    setLeadOfferState({
      ...leadOfferState,
      to_date: selectedDate,
    });
  };
  const handleExpiredDateChange = (toValue) => {
    const selectedDate = dayjs(toValue).format("YYYY-MM-DD");
    setLeadOfferState({
      ...leadOfferState,
      expired_at: selectedDate,
    });
  };

  const addFields = () => {
    setInputFields([...inputFields, { id: "", label: "", price: 0 }]);
  };

  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const handleDeleteLeadOfferPrices = (id, index) => {
    deleteLeadOfferPrices(id).unwrap().then((payload) => {
      if (payload?.success) {
        removeFields(index);
        setOpenSnackbar(true);
        handleClose();
      }
    }).catch((error) => {
      fnShowSnackBar(error || "Something went wrong", "error");
    });

  };
  const handleClose = () => {
    setOpen(false);
    setOpenSnackbar(false);
  };

  const handleFormChange = (index, event) => {
    const { name, value, type, checked } = event.target;
    setInputFields(
      inputFields.map((eng, i) =>
        i === index
          ? {
            ...eng,
            [name]:
              eng?.[name].en === undefined
                ? value
                : { ...eng?.[name], [selectedLanguage]: value },
          }
          : eng
      )
    );
  };
  const handleClickOpen = (index) => {
    setOpen(true);
    setIndex(index);
  };
  const addUpdateLeadOfferData = async (action) => {

    const newErrors = validateHotelData();
    setErrors(newErrors);
    if (!isEmpty(newErrors)) {
      setOpenError(true);
      return;
    }
    if (action === "add") {
      dispatch(setSelectedLeadOffer(null));
    }
    setShowBackDrop(true);
    resetCount();

    const isExistPrices = inputFields?.some((field) => field?.label);

    const formData = new FormData();

    formData.append("id", res_get?.data?.lead_offer?.id);
    formData.append("lead_id", lead_id);
    formData.append("airport_id", leadOfferState.airport_id);

    if (leadOfferState?.country_id != null) {
      formData.append("country_id", leadOfferState.country_id);
    }
    if (leadOfferState?.city_id != null) {
      formData.append("city_id", leadOfferState.city_id);
    }

    formData.append("hotel_id", leadOfferState.hotel_id);
    formData.append("expired_at", leadOfferState.expired_at);
    formData.append("status", leadOfferState.status || "draft");
    formData.append("to_date", leadOfferState.to_date);
    formData.append("from_date", leadOfferState.from_date);
    formData.append("flight_time", leadOfferState.flight_time || "");
    formData.append("no_guests", leadOfferState.no_guests || 0);
    formData.append(
      "lead_offer_service_ids",
      JSON.stringify(leadOfferState?.lead_offer_service_ids)
    );
    formData.append(
      "flight_return_time",
      leadOfferState.flight_return_time || ""
    );

    if (isExistPrices) {
      formData.append("prices", JSON.stringify(inputFields));
    };

    formData.append("detail", JSON.stringify(leadOfferState.detail));
    // formData.append(
    //   "price_includes",
    //   JSON.stringify(leadOfferState.price_includes)
    // );

    // return false;
    const res =
      action === "add"
        ? await addLeadOffer(formData)
        : await updateLeadOffer(formData);
    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message, "error");
    }
  };
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    setLeadOfferState(
      selectedLeadOffer
        ? {
          ...res_get?.data?.lead_offer,
          lead_offer_service_ids: leadOfferServiceIds ?? [],
          lead_type: res_get?.data?.lead_offer?.lead?.lead_type ?? [],
        }
        : {
          ...bluePrint,
          hotel_id: leadRequest?.data?.lead?.hotel_id ?? "",
          airport_id: leadRequest?.data?.lead?.airport_id ?? "",
          lead_type: leadRequest?.data?.lead?.lead_type ?? "",
          no_guests: leadRequest?.data?.lead?.no_guests ?? "",
          // hotel_id: res_get?.data?.lead_offer?.hotel_id ?? "",
          // airport_id: res_get?.data?.lead_offer?.airport_id ?? "",
          status: res_get?.data?.lead_offer?.status,
          to_date: leadRequest?.data?.lead?.to_date,
          from_date: leadRequest?.data?.lead?.from_date,
        }
    );
    setInputFields(
      res_get?.data?.lead_offer.prices ?? [{ id: "", label: "", price: 0 }]
    );
  }, [res_get, leadRequest, lead_id, selectedLeadOffer]);
  useEffect(() => {
    handleChangeHotel(
      leadRequest?.data?.lead?.hotel_id || res_get?.data?.lead_offer?.hotel_id,
      leadRequest?.data?.lead?.to_date,
      leadRequest?.data?.lead?.from_date
    );
  }, [
    res_get,
    leadRequest,
    leadRequest?.data?.lead?.hotel_id,
    res_get?.data?.lead_offer?.hotel_id,
    res_get_all_airports,
  ]);
  const airports = res_get_all_airports?.data?.region_airports?.map((airport) =>
    BLUE_PRINTS.airport.translate(airport, selectedLanguage)
  );

  const hotels = res_get_all_hotels?.data?.hotels?.map((hotel) => hotel);

  const leadOfferServiceIds =
    res_get?.data?.lead_offer?.lead_offer_services?.map((leadService) =>
      parseInt(leadService?.lead_service_id, 10)
    );

  const validateHotelData = () => {
    let newErrors = {};
    if (isEmpty(leadOfferState.hotel_id)) {
      newErrors = { ...newErrors, hotel_id: "Please select hotel" };
    }
    if (isEmpty(leadOfferState.airport_id)) {
      newErrors = { ...newErrors, airport_id: "Please select airport" };
    }
    if (isEmpty(leadOfferState.expired_at)) {
      newErrors = { ...newErrors, expired_at: "Please select expired at" };
    }
    if (isEmpty(leadOfferState.to_date)) {
      newErrors = { ...newErrors, to_date: "Please select to date" };
    }
    if (isEmpty(leadOfferState.from_date)) {
      newErrors = { ...newErrors, from_date: "Please select from date" };
    }

    return newErrors;
  };

  const handleChangeHotel = async (
    hotel_id = "",
    to_date = "",
    from_date = ""
  ) => {
    const data = {
      hotel_id: hotel_id || leadOfferState?.hotel_id,
      to_date: to_date || leadOfferState?.to_date,
      from_date: from_date || leadOfferState?.from_date,
    };

    try {
      // Await the async function to get the response
      const response = await getAirportWithPrice(data);
      console.log(response, "response");

      if (response?.data?.success) {
        const airportPrices = response?.data?.data?.airport_prices;
        const idsToFilter = Object.keys(airportPrices).map((id) =>
          parseInt(id)
        );
        const filteredAirportsWithPrices =
          res_get_all_airports?.data?.region_airports
            .filter((airport) => idsToFilter.includes(airport.id))
            .map((airport) => ({ airport: airport.name?.[selectedLanguage], price: airportPrices[airport.id], }));


            console.log("No success flag in response data", filteredAirportsWithPrices);
        setHotelAirport(filteredAirportsWithPrices);
        setFlightPrice(response?.data?.data?.flightPrice);
      } else {
        console.log("No success flag in response data", response?.data);
      }
    } catch (error) {
      console.error("Error fetching airport prices:", error);
    }
  };

  function isEmpty(value) {
    if (value === null || value === undefined) {
      return true;
    }
    if (typeof value === "string" && value.trim() === "") {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === "object" && Object.keys(value).length === 0) {
      return true;
    }
    return false;
  }
  function getFirstErrorMessage(errors) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        return errors[key];
      }
    }
    return "No errors found";
  }

  useEffect(() => {
    if (!leadOfferState?.detail) {
      setLeadOfferState((prev) => ({
        ...prev,
        detail: aboutYakSport,
      }));
    }
  }, [leadOfferState?.detail, aboutYakSport]);

  const countries = use_get_all_countries?.data?.countries?.map((country) =>
    BLUE_PRINTS.country.translate(country, selectedLanguage)
  );
  // const cities = use_get_all_cities?.data?.cities?.filter((city) => city?.country_id == hotelState?.country_id);
  const cities = (use_get_all_cities?.data?.cities || [])
    ?.filter((city) => city?.country_id == leadOfferState?.country_id)
    ?.map((city) => BLUE_PRINTS.city.translate(city, selectedLanguage));

  console.log("object", leadOfferState);
  return (
    <div>
      {openError && (
        <ErrorSnackbar
          error={getFirstErrorMessage(errors)}
          setOpenError={setOpenError}
        />
      )}
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {leadOfferState && hotels ? (
        <div style={addUpdateDivStyleGlobal}>
          <Grid container spacing={3.75}>
            {/*  */}
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZDropdown
                  error={
                    (error?.hotel_id && "This Field is required") ||
                    errors?.hotel_id
                  }
                  placeholder={"Select Hotel"}
                  value={leadOfferState?.hotel_id || ""}
                  data={hotels}
                  onChange={(e) => {
                    const selectedHotelId = e.target.value;
                    setLeadOfferState((x) => ({
                      ...x,
                      hotel_id: selectedHotelId,
                    }));
                    handleChangeHotel(selectedHotelId);
                  }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZDropdown
                  error={
                    (error?.airport_id && "This Field is required") ||
                    errors?.airport_id
                  }
                  placeholder={"Select Airport"}
                  value={leadOfferState?.airport_id || ""}
                  data={airports}
                  onChange={(e) => {
                    setLeadOfferState((x) => ({
                      ...x,
                      airport_id: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6} lg={6}>
                <DatePicker
                  label="Offer Expire Date "
                  defaultValue={
                    leadOfferState?.expired_at
                      ? dayjs(leadOfferState.expired_at)
                      : setLeadOfferState((x) => ({
                        ...x,
                        expired_at: dayjs().add(21, 'day').format("YYYY-MM-DD"),
                      }))
                  }
                  inputFormat="YYYY-MM-DD"
                  onChange={handleExpiredDateChange}
                  error={
                    (leadOfferState?.expired_at && "Please Select Date") ||
                    errors?.expired_at
                  }
                  slotProps={{ textField: { fullWidth: true, error: false } }}
                />
                {error?.expired_at && (
                  <p style={{ color: "red" }}>Please Select Date</p>
                )}
              </Grid>
            </LocalizationProvider>

            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DZSimpleDropDown
                  placeholder={"LeadOffer Status"}
                  value={leadOfferState?.status || "draft"}
                  data={lead_offer_status}
                  error={
                    (error?.status && "This Field is required") ||
                    errors?.status
                  }
                  onChange={(e) => {
                    setLeadOfferState((x) => ({
                      ...x,
                      status: e.target.value,
                    }));
                  }}
                />
              </Div>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
            <TextField  fullWidth label="No of Guest"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    noOfRows={1}
                    value={leadOfferState?.no_guests}
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setLeadOfferState((x) => ({
                        ...x,
                        no_guests: e.target.value,
                      }));
                    }}
                  />
              </Div>
</Grid>

            {
              leadOfferState?.lead_type == 'free_lead' &&
              <>
                <Grid item xs={12} md={12} lg={12}>
                  <DZLabel title={"Select Country and City"} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Div sx={{ mb: 0.5, mt: 1 }}>
                    <DZDropdown
                      placeholder={"Select Country"}
                      label="Select Country"
                      value={leadOfferState?.country_id ?? ""}
                      error={
                        error?.country_id || errors?.country_id
                      }
                      data={countries}
                      onChange={(e) => {
                        setLeadOfferState((x) => ({
                          ...x,
                          country_id: e.target.value,
                        }));
                      }}
                    />
                  </Div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Div sx={{ mb: 0.5, mt: 1 }}>
                    <DZDropdown
                      placeholder={"Select City"}
                      label="Select City"
                      value={leadOfferState?.city_id ?? ""}
                      error={error?.city_id || errors?.city_id}
                      data={cities}
                      onChange={(e) => {
                        setLeadOfferState((x) => ({
                          ...x,
                          city_id: e.target.value,
                        }));
                      }}
                    />
                  </Div>
                </Grid>
              </>}
            <Grid item xs={12} md={12} lg={12}>
              <DZLabel title={"Flight Times"} />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6} lg={6}>
                { leadOfferState?.from_date && <DatePicker
                  label="From Date"
                  defaultValue={dayjs(leadOfferState?.from_date)}
                  inputFormat="YYYY-MM-DD"
                  onChange={handleFromDateChange}
                  error={
                    (leadOfferState?.from_date && "Please Select Date") ||
                    errors?.from_date
                  }
                  slotProps={{ textField: { fullWidth: true, error: false } }}
                />}
                {error?.from_date && (
                  <p style={{ color: "red" }}>Please Select Date</p>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                { leadOfferState?.to_date && <DatePicker
                  label="To Date"
                  defaultValue={dayjs(leadOfferState?.to_date)}
                  error={
                    (leadOfferState?.to_date && "Please Select Date") ||
                    errors?.to_date
                  }
                  inputFormat="YYYY-MM-DD"
                  onChange={handleToDateChange}
                  slotProps={{ textField: { fullWidth: true, error: false } }}
                />}
              </Grid>
            </LocalizationProvider>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <TextField
                  id="time"
                  label="Flight time"
                  type="time"
                  value={leadOfferState?.flight_time}
                  error={
                    (error?.flight_time && "This Field is required") ||
                    errors?.flight_time
                  }
                  onChange={(e) => {
                    setLeadOfferState((x) => ({
                      ...x,
                      flight_time: e.target.value,
                    }));
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: "100%" }}
                />
              </Div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <TextField
                  id="time"
                  label="Flight Home"
                  type="time"
                  value={leadOfferState?.flight_return_time}
                  error={
                    (error?.flight_return_time && "This Field is required") ||
                    errors?.flight_return_time
                  }
                  onChange={(e) => {
                    setLeadOfferState((x) => ({
                      ...x,
                      flight_return_time: e.target.value,
                    }));
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  sx={{ width: "100%" }}
                />
              </Div>
            </Grid>
          </Grid>

          <div style={{ width: "100%" }}>
            <DZLabel title={"Prices"} />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
              }}
            >
              <Button
                size="small"
                variant="outlined"
                startIcon={<ControlPointOutlinedIcon />}
                onClick={addFields}
              >
                Add More
              </Button>
            </div>
          </div>

          <form>
            <Card sx={{ overflow: "visible", mt: 4, border: 1 }}>
              <CardContent>
                {inputFields?.map((input, index) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "end",
                          width: "100%",
                        }}
                      >
                        {index1 === index ? (
                          <DZDeleteDialog
                            title={
                              "Are you sure about deleting this Attribute?"
                            }
                            description={
                              " You won't be able to recover this attribute later"
                            }
                            openBox={open}
                            handleClose={() => handleClose()}
                            handleDeleteFunctionCall={() =>
                              handleDeleteLeadOfferPrices(input?.id, index)
                            }
                          />
                        ) : (
                          ""
                        )}

                        {input?.id ? (
                          <Button
                            variant="outlined"
                            startIcon={<DeleteIcon />}
                            onClick={() => handleClickOpen(index)}
                          >
                            Delete
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            startIcon={<DeleteIcon />}
                            sx={{ color: "red" }}
                            onClick={() => removeFields(index)}
                          >
                            Remove
                          </Button>
                        )}
                      </div>
                      <div key={index}>
                        <input
                          type="hidden"
                          name="id"
                          value={input?.id}
                          onChange={(event) => handleFormChange(index, event)}
                        />
                        <Grid container spacing={3.75}>
                          <Grid item xs={12} md={8} lg={8}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>
                              <TextField
                                fullWidth
                                label="* Price per pers. in a 3 pers. room"
                                type="text"
                                name="label"
                                variant="outlined"
                                noOfRows={1}
                                value={input.label}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              />
                            </Div>
                          </Grid>
                          <Grid item xs={12} md={4} lg={4}>
                            <Div sx={{ mb: 0.5, mt: 1 }}>
                              <TextField
                                fullWidth
                                label="Price"
                                InputLabelProps={{ shrink: true }}
                                type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      DKK
                                    </InputAdornment>
                                  ),
                                }}
                                name="price"
                                variant="outlined"
                                noOfRows={1}
                                value={input.price}
                                onChange={(event) =>
                                  handleFormChange(index, event)
                                }
                              />
                            </Div>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  );
                })}
              </CardContent>
            </Card>
          </form>

          <div style={{ width: "100%" }}>
            {flightPrice && <DZLabel title={"Hotel Airport Prices"} />}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
              }}
            >
              <DZLabel title={`Flight Price: ${flightPrice}`} />
            </div>
          </div>

          {leadOfferState?.hotel_id && (
            <form>
              <Card sx={{ overflow: "visible", mt: 2, border: 1 }}>
                <CardContent>
                  {hotelAirport?.map((input, index) => {
                    return (
                      <>
                        <div key={index}>
                          <Grid container spacing={3.75}>
                            <Grid item xs={12} md={8} lg={8}>
                              <Div sx={{ mb: 0.5, mt: 1 }}>
                                <TextField
                                  fullWidth
                                  label="Airport"
                                  type="text"
                                  name="label"
                                  variant="outlined"
                                  noOfRows={1}
                                  InputLabelProps={{ shrink: true }}
                                  value={input.airport}
                                // onChange={(event) =>
                                //   handleFormChange(index, event)
                                // }
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                              <Div sx={{ mb: 0.5, mt: 1 }}>
                                <TextField
                                  fullWidth
                                  label="Price"
                                  InputLabelProps={{ shrink: true }}
                                  type="number"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        DKK
                                      </InputAdornment>
                                    ),
                                  }}
                                  name="price"
                                  variant="outlined"
                                  noOfRows={1}
                                  value={input.price}
                                // onChange={(event) =>
                                //   handleFormChange(index, event)
                                // }
                                />
                              </Div>
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    );
                  })}
                </CardContent>
              </Card>
            </form>
          )}

          <DZLabel title={"Lead Services"} />
          <div className="col2__box">
            {leadData?.map((lead) => (
              <Grid item xs={2} md={2} lg={2} key={lead?.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={leadOfferState?.lead_offer_service_ids?.includes(
                        lead?.id
                      )}
                      name={lead?.title?.[selectedLanguage]}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setLeadOfferState((prevState) => ({
                          ...prevState,
                          lead_offer_service_ids: checked
                            ? [...prevState?.lead_offer_service_ids, lead?.id]
                            : prevState?.lead_offer_service_ids.filter(
                              (id) => id !== lead?.id
                            ),
                        }));
                      }}
                    />
                  }
                  label={lead?.title?.[selectedLanguage]}
                />
              </Grid>
            ))}
          </div>

          <Grid container spacing={3.75}>
            <Grid item xs={12} md={12} lg={12}>
              <DzTextEditorMultilingual
                data={
                  leadOfferState?.detail?.[selectedLanguage] ||
                  aboutYakSport?.[selectedLanguage]
                }
                onChange={(newContent) => {
                  setLeadOfferState((x) => ({
                    ...x,
                    detail: { ...x?.detail, [selectedLanguage]: newContent },
                  }));
                }}
              />
            </Grid>
          </Grid>

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedLeadOffer(null));
              }}
            >
              Cancel
            </Button>
            {selectedLeadOffer?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadOfferData();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadOfferData("add");
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateLeadOffer;
