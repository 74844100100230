import {
  Box,
  Button,
  TextField,
  Fab,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import {
  selectedReferenceSelector,
  setSelectedReference,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddReferenceMutation,
  useGetReferenceQuery,
  useUpdateReferenceMutation,
  useGetAllRegionsQuery,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField from "../../../@jumbo/dz-components/textboxes/DZTextField";

import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";

import Grid from "@mui/material/Grid";
import { Config } from "constant";
import DZLabel, {
  DzNoBackgroundLabel,
} from "@jumbo/dz-components/labels/DZLabel";

import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";

import Div from "@jumbo/shared/Div";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import DzTextEditorMultilingual from "@jumbo/dz-components/TextEditor/DzTextEditorMultilingual";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

// import { AddCircle } from "@material-ui/icons";
const textContent =
  "<h2>Awesome Rich Content</h2>\n" +
  "<p>Suspendisse id sollicitudin dui. <strong>Vestibulum eu sapien pharetra,</strong> bibendum ligula id, ullamcorper ligula.</p>\n" +
  "\n" +
  "<ul>\n" +
  "        <li>ullamcorper ligula</li>\n" +
  "        <li>Duis vel neque</li>\n" +
  "</ul>\n" +
  "\n" +
  "<p><em>Sed feugiat hendrerit risus, quis efficitur massa facilisis vitae. Aliquam erat volutpat. </em></p>\n";
const AddUpdateReference = ({ onClose, learning_journey_id, selectedId }) => {
  // const chatbot_types = ['scale', 'note', 'buddy'];
  const { showDialog, hideDialog } = useJumboDialog();
  const { fnShowSnackBar } = useSnackBarManager();

  const dispatch = useDispatch();

  const selectedReference = useSelector(selectedReferenceSelector);
  console.log(selectedReference);

  const { data: res_get } = useGetReferenceQuery(selectedReference?.id);
  const { data: use_get_all_regions } = useGetAllRegionsQuery();
  // console.log("ghfffhgh", res_get)
  const [updateReference, responseUpdate] = useUpdateReferenceMutation();
  const [addReference, responseAdd] = useAddReferenceMutation();

  const bluePrint = BLUE_PRINTS.reference;
  // const bluePrintEngagement = BLUE_PRINTS.engagement;
  // console.log(bluePrintEngagement);

  const [referenceState, setReferenceState] = useState(null);
  const [editorState, setEditorState] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [selectedFile, setSelectedFile] = useState();

  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);
  // console.log(selectedLanguage);
  const regionIds = res_get?.data?.regions?.map((region) =>
    parseInt(region?.region_id, 10)
  );

  useEffect(()=> {
    setSelectedFile(res_get?.data?.reference?.image)
  },[res_get?.data?.reference?.image])

  // console.log(`Size groups:`,sizesName)
  useEffect(() => {
    setReferenceState(
      selectedReference
        ? { ...res_get?.data?.reference, regions_id: regionIds ?? [] }
        : bluePrint
    );

    // setEditorState(res_get?.data?.reference?.description?.[selectedLanguage])
  }, [res_get]);

  const addUpdateReferenceData = async (action) => {
    const formData = new FormData();

    formData.append("id", res_get?.data?.reference?.id);
    formData.append("image", selectedFile);
    formData.append("title", JSON.stringify(referenceState.title));
    formData.append("description", JSON.stringify(referenceState.description));
    formData.append("regions_id", JSON.stringify(referenceState.regions_id));

    // return false;

    const res =
      action === "add"
        ? await addReference(formData)
        : await updateReference(formData);

    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
  };

  const fileUpload = (e) => {
    if (e?.target?.files?.length !== 0) {
      setSelectedFile(e?.target?.files[0]);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader?.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const regions = use_get_all_regions?.data?.regions?.map((region) => region);

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {referenceState ? (
        <div style={addUpdateDivStyleGlobal}>
          {imagePreview ? (
            <img
              src={imagePreview}
              alt={selectedFile}
              style={{
                height: 100,
                width: 100,
                borderRadius: 50,
                alignSelf: "center",
              }}
            />
          ) : (
            <DZImageCircle
              image={selectedFile}
              link={Config.digitalOceanLinkReferenceImg}
              style={{
                height: 100,
                width: 100,
                borderRadius: 50,
                alignSelf: "center",
              }}
            />
          )}

          <Box
            sx={{
              display: "flex",
              paddingBottom: "0px",
              justifyContent: "center",
              paddingTop: "0px",
            }}
          >
            <label htmlFor="upload-photo">
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload-photo"
                type="file"
                onChange={fileUpload}
              />
              <Fab
                color="primary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                Upload photo
              </Fab>
            </label>
          </Box>

          <Grid container spacing={3.75}>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <MultiDZTextValidation
                  error={error?.[0]}
                  label="Title"
                  variant="outlined"
                  value={referenceState?.title?.[selectedLanguage] || ""}
                  onChange={(e) => {
                    setReferenceState((x) => ({
                      ...x,
                      title: { ...x.title, [selectedLanguage]: e.target.value },
                    }));
                  }}
                />
              </Div>
            </Grid>

            {error?.[1] && (
              <p style={{ color: "red" }}>Detail field is required.</p>
            )}
            {/* regions */}
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DzNoBackgroundLabel title={"Regions"} />
              </Div>
            </Grid>
            {regions?.map((region) => (
              <Grid item xs={5} md={2} lg={2} key={region?.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={referenceState?.regions_id?.includes(region?.id)}
                      name={region?.language}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setReferenceState((prevState) => ({
                          ...prevState,
                          regions_id: checked
                            ? [...prevState?.regions_id, region?.id]
                            : prevState?.regions_id.filter(
                                (id) => id !== region?.id
                              ),
                        }));
                      }}
                    />
                  }
                  label={region?.language}
                />
              </Grid>
            ))}
          </Grid>

          <DzTextEditorMultilingual
            data={referenceState?.description?.[selectedLanguage] || ""}
            onChange={(newContent) => {
              setReferenceState((x) => ({
                ...x,
                description: {
                  ...x.description,
                  [selectedLanguage]: newContent,
                },
              }));
            }}
          />
          {/* <CKEditor
 initData={ referenceState?.description?.[selectedLanguage] }

      onChange={(event)=>{ 
        const data = event.editor.getData();
    
    setReferenceState((x) => ({
      ...x,
      description: { ...x.description, [selectedLanguage]: data },
    }));}}
    
            config={{
                contentsCss: [`${process.env.PUBLIC_URL}/fonts/noir-pro/styles.css`,
                    `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`],
            }}
            
        /> */}

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                hideDialog();
                dispatch(setSelectedReference(null));
              }}
            >
              Cancel
            </Button>
            {res_get?.data?.reference?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateReferenceData();
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedReference(null));
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateReferenceData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedReference(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
          {/* dispatch(setSelectedReference(null)); */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateReference;
