import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import {
  Button,
  TextField,
  Fab,
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";
import {
  selectedHotelSportSelector,
  setSelectedHotelSport,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddHotelSportMutation,
  useGetHotelSportQuery,
  useAddUpdateHotelSportMutation,
  useDeleteHotelSportMutation,
  useGetAllHotelSportsQuery,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar, {
  NoHideSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";

import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import DZLabel, { DZDataLabel } from "@jumbo/dz-components/labels/DZLabel";
import ControlPointOutlinedIcon from "@mui/icons-material/ControlPointOutlined";
import DZDeleteDialog from "@jumbo/dz-components/dialog/DZDeleteDialog";
import DeleteIcon from "@mui/icons-material/Delete";

import Div from "@jumbo/shared/Div/Div";
import DzTextEditorMultilingual from "@jumbo/dz-components/TextEditor/DzTextEditorMultilingual";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateHotelSport = ({ onClose, hotelId, onUpdateHotelSport }) => {
  const [inputFields, setInputFields] = useState([
    { id: "", name: LANGUAGES.defaultObject, show: "" },
  ]);
  const [open, setOpen] = React.useState(false);
  const [index1, setIndex] = React.useState(null);
  const dispatch = useDispatch();
  const selectedHotelSport = useSelector(selectedHotelSportSelector);
  const [deleteHotelSportAttribute, responseDelete] =
    useDeleteHotelSportMutation();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const {
    data: res_get,
    isLoading: refetc,
    error: errorHotelSport,
  } = useGetHotelSportQuery([selectedHotelSport?.id, hotelId]);
  const { fnShowSnackBar } = useSnackBarManager();

  const [updateHotelSport, responseUpdate] = useAddUpdateHotelSportMutation();

  const bluePrint = BLUE_PRINTS.hotelSport;

  const [hotelSportState, setHotelSportState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);

  const [showBackDrop, setShowBackDrop] = useState(false);

  const addUpdateHotelSportData = async (action) => {
    const formData = new FormData();
    // formData.append('image', selectedFile);
    formData.append("id", res_get?.data?.hotel_sports_detail?.id);
    formData.append("hotel_id", hotelId);
    formData.append("sports_id", hotelSportState?.sports_id);
    if (
      hotelSportState.detail?.en ||
      hotelSportState.detail?.de ||
      hotelSportState.detail?.sv ||
      hotelSportState.detail?.da ||
      hotelSportState.detail?.no
    ) {
      formData.append("detail", JSON.stringify(hotelSportState.detail));
    } else {
      formData.append(
        "detail",
        JSON.stringify({ en: "", da: "", sv: "", de: "", no: "" })
      );
    }
    formData.append("show_no", hotelSportState.show || 0);
    formData.append("sportsAttributes", JSON.stringify(inputFields) || []);
    const res = await updateHotelSport(formData)
      .unwrap()
      .then((payload) => {
        onUpdateHotelSport();
      })
      .catch((error) => {});
    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
  };

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseUpdate);

  useEffect(() => {
    setHotelSportState(
      res_get?.data?.hotel_sports_detail
        ? res_get?.data?.hotel_sports_detail
        : bluePrint
    );

    setInputFields(
      res_get?.data?.hotel_sports_attribute.length > 0
        ? res_get?.data?.hotel_sports_attribute
        : [{ id: "", name: LANGUAGES.defaultObject, show: "" }]
    );
  }, [res_get]);
  const handleFormChange = (index, event) => {
    const { name, value, type, checked } = event.target;
  
    setInputFields(
      inputFields.map((field, i) => {
        if (i === index) {
          if (type === "checkbox") {
            return {
              ...field,
              [name]: checked ? 1 : 0,
            };
          } else if (name === "name") {
            // Ensure the name object maintains its structure
            return {
              ...field,
              [name]: {
                ...LANGUAGES.defaultObject, // Ensure all language keys are present
                ...field[name], // Retain existing values
                [selectedLanguage]: value, // Update the specific language
              },
            };
          } else {
            return {
              ...field,
              [name]: value,
            };
          }
        } else {
          return field;
        }
      })
    );
  };
  // const handleFormChange = (index, event) => {
  //   const { name, value, type, checked } = event.target;
    
  //   setInputFields(
  //     inputFields.map((eng, i) =>
  //       i === index
  //         ? {
  //             ...eng,
  //             [name]:
  //               type == "checkbox"
  //                 ? checked
  //                   ? 1
  //                   : 0
  //                 : eng?.[name].en === undefined
  //                 ? value
  //                 : { ...eng?.[name], [selectedLanguage]: value },
  //           }
  //         : eng
  //     )
  //   );
  // };

  const addFields = () => {
    setInputFields([
      ...inputFields,
      { id: "", name: LANGUAGES.defaultObject, show: 0 },
    ]);
  };
  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };
  const deleteHotelSportAttributes = (id, index) => {
    deleteHotelSportAttribute(id);
    removeFields(index);
    setOpenSnackbar(true);
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
    // refetch();
  };
  const handleClickOpen = (index) => {
    setOpen(true);
    setIndex(index);
  };

  console.log(hotelSportState, "hotelSportState");

  return (
    <div>
      {responseDelete?.isSuccess ? (
        <NoHideSnackbar
          responseAdd={responseDelete?.isSuccess === true ? responseDelete : ""}
        />
      ) : (
        ""
      )}
      {showBackDrop && <SimpleBackdrop responseUpdate={responseUpdate} />}
      <Snackbar
        responseAdd={responseUpdate?.isSuccess === true && responseUpdate}
      />

      {hotelSportState ? (
        <div style={addUpdateDivStyleGlobal}>
          <FormControlLabel
            control={
              <Checkbox
                checked={hotelSportState?.show != 0}
                onChange={(e) => {
                  setHotelSportState((x) => ({
                    ...x,
                    show: e.target.checked,
                  }));
                }}
              />
            }
            label="Is Show Hotel Sport"
          />

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
            }}
          >
            <DZDataLabel
              title={"Hotel"}
              children={res_get?.data?.hotel?.name}
            />
            <DZDataLabel
              title={"Sport "}
              children={res_get?.data?.sport?.name?.[selectedLanguage]}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <DZLabel mt="0" title={"Hotel Sport Attributes"} />

            <Button
              variant="outlined"
              startIcon={<ControlPointOutlinedIcon />}
              onClick={addFields}
            >
              Add More
            </Button>
          </div>
          <form
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
            }}
          >
            {inputFields?.map((input, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    padding: "10px",
                    alignItems: "start",
                    borderRadius: "10px",
                    background: "#7352C710",
                  }}
                  key={index}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={input?.show}
                        name="show"
                        onChange={(event) => handleFormChange(index, event)}
                      />
                    }
                    label="Is Show"
                  />
                  <input
                    type="hidden"
                    name="id"
                    value={input?.id}
                    style={{ width: "100%" }}
                    onChange={(event) => handleFormChange(index, event)}
                  />
                  <TextField
                    fullWidth
                    label="Title"
                    type="text"
                    name="name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    noOfRows={1}
                    value={input?.name?.[selectedLanguage] }
                    sx={{ width: "100%" }}
                    onChange={(event) => handleFormChange(index, event)}
                  />

                  {index1 === index ? (
                    <DZDeleteDialog
                      title={"Are you sure about deleting this Attribute?"}
                      description={
                        " You won't be able to recover this attribute later"
                      }
                      openBox={open}
                      handleClose={() => handleClose()}
                      handleDeleteFunctionCall={() =>
                        deleteHotelSportAttributes(input?.id, index)
                      }
                    />
                  ) : (
                    ""
                  )}

                  {input?.id ? (
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleClickOpen(index)}
                    >
                      Delete
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      sx={{ color: "red" }}
                      onClick={() => removeFields(index)}
                    >
                      Remove
                    </Button>
                  )}
                </div>
              );
            })}
          </form>
          <DzTextEditorMultilingual
            data={hotelSportState?.detail?.[selectedLanguage]}
            onChange={(newContent) => {
              setHotelSportState((x) => ({
                ...x,
                detail: { ...x.detail, [selectedLanguage]: newContent },
              }));
            }}
          />

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedHotelSport(null));
              }}
            >
              Cancel
            </Button>
            {
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateHotelSportData();
                  dispatch(setSelectedHotelSport(null));
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            }
            {/* dispatch(setSelectedHotelSport(null)); */}
          </div>
          {/* <div style={{ width: '10%' }}>{JSON.stringify(courseState)}</div> */}
        </div>
      ) : (
        <div>Loading....</div>
      )}
      {/* {!selectedHotelSport &&
        (responseAdd.isLoading?<div>Loading.....</div>
        : responseAdd.isError?<div>{JSON.stringify(responseAdd.error)}</div>
        : responseAdd.isSuccess?<div>{JSON.stringify(responseAdd.data)}.</div>
        : <div>Click Add Button .....</div>)
      } */}
    </div>
  );
};

export default AddUpdateHotelSport;
