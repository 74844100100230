import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, {  useState } from 'react'

import { useGetAllAirportsQuery, BLUE_PRINTS, useDeleteAirportMutation,  } from 'app/rkt_query/storeApis';
import { useDispatch } from 'react-redux';

import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';

import { setSelectedAirport } from 'app/rkt_query/SelectedStuffReducer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';


import ViewAirport from './ViewAirport';
import AddUpdateAirport from './AddUpdateAirport.js';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';


const AirportList = () => {

  const dispatch = useDispatch();


  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteAirport, responseDelete] = useDeleteAirportMutation();



  const { data: res_get_all, } = useGetAllAirportsQuery();
  const [showMode, setShowMode] = useState(false);
  const bluePrint = BLUE_PRINTS.airport
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedAirport(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedAirport(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedAirport(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedAirport(row)); }
  bluePrint.handleDelete = (id) => { deleteAirport(id); }
  return (
    <>
    
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      {/* <Snackbar responseAdd={responseDelete} /> */}
      <JumboDemoCard
        title="Airports"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >

        <div style={{ width: "100%" }}>
          <DataTable
            btnTitleAdd="Airport"
            data={res_get_all?.data?.region_airports}
            bluePrint={bluePrint}
            showLanguageSwitcher={true}
            ViewModel={() => <ViewAirport />}
            ModalContent={() => <AddUpdateAirport onClose={hideDialog}  showLanguageSwitcher={false}  fullscreen={true} />}
            showAddButton={true}
          
          />
         </div>
      </JumboDemoCard>
    </>
  );
}

export default AirportList