import { Button } from "@mui/material";
import {
  selectedCountrySelector,
  setSelectedCountry,
} from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddCountryMutation,
  useGetAllRegionsQuery,
  useGetCountryQuery,
  useUpdateCountryMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar, {
  ErrorSnackbar,
} from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation, {
  MultiDZTextValidation,
} from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import Grid from "@mui/material/Grid";

import { Checkbox, FormControlLabel } from "@mui/material";
import { DzNoBackgroundLabel } from "@jumbo/dz-components/labels/DZLabel";
import Div from "@jumbo/shared/Div/Div";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
const AddUpdateCountry = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedCountry = useSelector(selectedCountrySelector);
  const { data: res_get } = useGetCountryQuery(selectedCountry?.id);
  const { data: use_get_all_regions } = useGetAllRegionsQuery();
  const [updateCountry, responseUpdate] = useUpdateCountryMutation();
  const [addCountry, responseAdd] = useAddCountryMutation();
  const bluePrint = BLUE_PRINTS.country;
  const [countryState, setCountryState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [errors, setErrors] = useState({});
  const [openError, setOpenError] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();

  const addUpdateCountryData = async (action) => {
    const newErrors = validateHotelData();
    setErrors(newErrors);
    if (!isEmpty(newErrors)) {
      setOpenError(true);
      return;
    }
    const formData = new FormData();
    formData.append("id", res_get?.data?.country?.id);
    formData.append("name", JSON.stringify(countryState.name));
    formData.append("regions_id", JSON.stringify(countryState.regions_id));
    const res =
      action === "add"
        ? await addCountry(formData)
        : await updateCountry(formData);
    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
  };
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);
  const regionIds = res_get?.data?.regions?.map((region) =>
    parseInt(region?.region_id, 10)
  );

  useEffect(() => {
    setCountryState(
      selectedCountry
        ? { ...res_get?.data?.country, regions_id: regionIds ?? [] }
        : bluePrint
    );
  }, [res_get]);
  console.log(countryState);
  const regions = use_get_all_regions?.data?.regions?.map((region) => region);
  function isEmpty(value) {
    if (value === null || value === undefined) {
      return true;
    }
    if (typeof value === "string" && value.trim() === "") {
      return true;
    }
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }
    if (typeof value === "object" && Object.keys(value).length === 0) {
      return true;
    }
    return false;
  }
  const validateHotelData = () => {
    let newErrors = {};
    if (isEmpty(countryState?.regions_id)) {
      newErrors = { ...newErrors, name: "Please select the Region" };
    }

    return newErrors;
  };
  function getFirstErrorMessage(errors) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        return errors[key];
      }
    }
    return "No errors found";
  }
  return (
    <div>
      {openError && (
        <ErrorSnackbar
          error={getFirstErrorMessage(errors)}
          setOpenError={setOpenError}
        />
      )}
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />
      {countryState ? (
        <div style={addUpdateDivStyleGlobal}>
          <MultiDZTextValidation
            error={error?.[0]}
            label="Name"
            variant="outlined"
            value={countryState?.name?.[selectedLanguage] || ""}
            onChange={(e) => {
              setCountryState((x) => ({
                ...x,
                name: { ...x.name, [selectedLanguage]: e.target.value },
              }));
            }}
            placeholder="Name"
          />
          <Grid container spacing={3.75}>
            <Grid item xs={12} md={12} lg={12}>
              <Div sx={{ mb: 0.5, mt: 1 }}>
                <DzNoBackgroundLabel title={"Regions"} />
              </Div>
            </Grid>
            {regions?.map((region) => (
              <Grid item xs={5} md={2} lg={2} key={region?.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={countryState?.regions_id?.includes(region?.id)}
                      name={region?.language}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setCountryState((prevState) => ({
                          ...prevState,
                          regions_id: checked
                            ? [...prevState?.regions_id, region?.id]
                            : prevState?.regions_id.filter(
                                (id) => id !== region?.id
                              ),
                        }));
                      }}
                    />
                  }
                  label={region?.language}
                />
              </Grid>
            ))}
          </Grid>
          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedCountry(null));
              }}
            >
              Cancel
            </Button>
            {selectedCountry?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateCountryData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateCountryData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedCountry(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateCountry;
