import { Button, TextField, Fab, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { MultiDZTextValidation } from "@jumbo/dz-components/textboxes/DZTextValidation";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import {
  selectedContractConditionTypeSelector,
  setSelectedContractConditionType,
} from "app/rkt_query/SelectedStuffReducer";
import {
  BLUE_PRINTS,
  useAddContractConditionTypeMutation,
  useGetContractConditionTypeQuery,
  useUpdateContractConditionTypeMutation,
} from "app/rkt_query/storeApis";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const AddUpdateContractType = ({ onClose }) => {
  const { fnShowSnackBar } = useSnackBarManager();

  const dispatch = useDispatch();
  const selectedContractType = useSelector(
    selectedContractConditionTypeSelector
  );
  const {
    data: res_get,
    isLoading: isLoadingContractType,
    error: errorContractType,
  } = useGetContractConditionTypeQuery(selectedContractType?.id);

  const [updateContractType, responseUpdate] =
    useUpdateContractConditionTypeMutation();
  const [addContractType, responseAdd] = useAddContractConditionTypeMutation();
  const bluePrint = BLUE_PRINTS?.contractConditionType;

  const [disable, setDisabled] = useState([true]);

  const [ContractTypeState, setContractTypeState] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const { hideDialog } = useJumboDialog();
  const [showBackDrop, setShowBackDrop] = useState(false);

  const addUpdateContractTypeData = async (action) => {
    const formData = new FormData();
    formData.append("id", res_get?.data?.contract_condition_type?.id);
    formData.append("name", JSON.stringify(ContractTypeState?.name));
    const res =
      action === "add"
        ? await addContractType(formData)
        : await updateContractType(formData);

    if (res?.data?.message) {
      fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message,"error");
    }
  };

  //this code is use to control infinity loops and backend required error. custom hook
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    setContractTypeState(
      selectedContractType ? res_get?.data?.contract_condition_type : bluePrint
    );
    setDisabled(selectedContractType ? false : true);
  }, [res_get]);

  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {ContractTypeState ? (
        <div style={addUpdateDivStyleGlobal}>
          <MultiDZTextValidation
            error={error?.[0] && "The Field is required"}
            label="Name"
            variant="outlined"
            value={ContractTypeState?.name[selectedLanguage] || ""}
            onChange={(e) => {
              setContractTypeState((x) => ({
                ...x,
                name: { ...x.name, [selectedLanguage]: e.target.value },
              }));
            }}
            placeholder="Name"
          />

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedContractConditionType(null));
              }}
            >
              Cancel
            </Button>
            {selectedContractType?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateContractTypeData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateContractTypeData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedContractConditionType(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateContractType;
