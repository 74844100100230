import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BLUE_PRINTS,
  useGetLeadQuery,
  useUpdateLeadStatusMutation,
  useUpdateLeadUserMutation,
} from "app/rkt_query/storeApis";
import { useState, useEffect } from "react";
import { selectedLeadSelector, selectedLeadIdSelector, selectedLeadUniqueIdSelector } from "app/rkt_query/SelectedStuffReducer";
import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import DZGenericView, {
  DZGenericTextField,
} from "@jumbo/dz-components/dzgeneric/DZGenericView";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Div from "@jumbo/shared/Div";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import { useUpdateLeadMutation } from "app/rkt_query/storeApis";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";

import { setSelectedLead } from "app/rkt_query/SelectedStuffReducer";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: 10,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  border: `0px solid ${theme.palette.divider}`,
  margin: `0px 0px 40px 0px`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function CustomizedAccordions() {
  const lead_status=['waiting','pending','success','rejected','deleted'];
  const location = useLocation();

  //const selectedLead = useSelector(selectedLeadSelector);
  
  const [leadUser, setLeadUser] = useState(null);
  const [lead, setLead] = useState(null);
  const selectedLanguage = useSelector(selectedLanguageSelector);
  const [updateLeadUser, responseUpdateLeadUser] = useUpdateLeadUserMutation();
  const [updateLead, responseUpdateLead] = useUpdateLeadStatusMutation();
  // const [LeadId, setLeadId] = useState(location?.state?.lead_id);
  const [LeadId, setLeadId] = useState(useSelector(selectedLeadIdSelector));
  const [LeadUniqueId, setLeadUniqueId] = useState(useSelector(selectedLeadUniqueIdSelector));
  const [userResponse, setUserResponse] = useState();
  const {
    data: res_get,
    isLoading: isLoadingLead,
    error: errorLead,
  } = useGetLeadQuery(LeadId);
  const leadType = res_get?.data?.lead?.lead_type;
  const bluePrint = BLUE_PRINTS.lead;

  const LeadArray = [
    { label: "Lead type", data: res_get?.data?.lead?.lead_type,field_name: "lead_type", },
    {  label: "Country", data: res_get?.data?.lead?.country?.name?.[selectedLanguage], field:"simple",  },
    { label: "City", data: res_get?.data?.lead?.city?.name?.[selectedLanguage],field:"simple", },
    { label: "Hotel", data: res_get?.data?.lead?.hotel?.name,field:"simple", },
    { label: "Lead Status", data: res_get?.data?.lead?.status,field_name: "status",field:"simple_dropdown",array:lead_status },
    { label: "Airport", data: res_get?.data?.lead?.airport?.name?.[selectedLanguage],field:"simple", },
    { label: "From Date", data: res_get?.data?.lead?.from_date ,field_name: "from_date", field:"simple"},
    { label: "To Date", data: res_get?.data?.lead?.to_date,field_name: "to_date",field:"simple" },
  ];
  const LeadUserArray = [
    {
      label: "First name",
      data: res_get?.data?.lead?.lead_user?.first_name,
      field_name: "first_name",
    },
    {
      label: "Last name",
      data: res_get?.data?.lead?.lead_user?.last_name,
      field_name: "last_name",
    },
    {
      label: "E-mail",
      data: res_get?.data?.lead?.lead_user?.email,
      field_name: "email",
    },
    {
      label: "Telephone",
      data: res_get?.data?.lead?.lead_user?.mobile,
      field_name: "mobile",
    },
  ];


  const [showBackDrop, setShowBackDrop] = useState(false);

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    setLeadUser(res_get?.data?.lead?.lead_user);
    setLead(res_get?.data?.lead);
  }, [res_get]);
  const { fnShowSnackBar } = useSnackBarManager();

  const updateLeadUserData = async(action) => {


    const formData = new FormData();

    formData.append("id", leadUser?.id);
    formData.append("first_name", leadUser.first_name);
    formData.append("last_name", leadUser.last_name);
    formData.append("email", leadUser.email);
    formData.append("mobile", leadUser.mobile);

    const res = await updateLeadUser(formData)
      .unwrap( )
      .then((payload) => {
        if (payload?.success) {
         window.location.reload();
        }
      })
      .catch((error) => {

      });
      if (res?.data?.message) {
        fnShowSnackBar(res?.data?.message|| "Done successfully");
      } else {
        fnShowSnackBar(res?.error?.data?.message,"error");
      }
      // if (response.message === "successfully") {
      //   const fetchUserToken = response?.data?.access_token;
      //   if (fetchUserToken) {

      
         

      //     fnShowSnackBar("User successfully logged in!");
      //     window.location.reload();
        
          
      //   } else {
      //     fnShowSnackBar("User login Failed",true);
      //     // console.error("Login failed: Access token not found in response.");
      //   }
      // } else {
      //   // setMessage("User login Failed");
      //   // console.error("Login failed: ", response.message);
      //   fnShowSnackBar("Invalid Credentials",true);
      // }
  };
  const updateLeadData = async() => {


    const formData = new FormData();

    formData.append("id", lead?.id);
    formData.append("status", lead.status);
 

    const response = await updateLead(formData)
      .unwrap( )
      .then((payload) => {
        // console.log(payload,"response");
        if (payload?.success) {
         window.location.reload();
          console.log(payload.message);
       
        }

      })
      .catch((error) => {});
  };

  return (
    <div>
     {showBackDrop && <SimpleBackdrop  responseUpdate={responseUpdateLeadUser} />}
     {showBackDrop && <SimpleBackdrop  responseUpdate={responseUpdateLead} />}
      <Snackbar responseAdd={responseUpdateLeadUser?.isSuccess === true ? responseUpdateLeadUser : ""} />
      <Snackbar responseAdd={responseUpdateLead?.isSuccess === true ? responseUpdateLead : ""} />

      <Accordion defaultExpanded={true} onChange={handleChange("panel2")}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>
            <b>Lead User #:</b> {res_get?.data?.lead?.lead_user_id}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              {errorLead ? (
                <div>{JSON.stringify(errorLead)}</div>
              ) : isLoadingLead ? (
                <div>Loading Single Lead.....</div>
              ) : res_get?.data?.lead ? (
                <DZGenericTextField
                  obj={LeadUserArray}
                  closeBtn={false}
                  grid={true}
                  marginTop={0}
                  setStateData={setLeadUser}
                  state={leadUser}
                
                  onSubmit={updateLeadUserData}
                  setShowBackDrop={setShowBackDrop}
                />
              ) : (
                <div>Bad Code</div>
              )}
            </Div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true} onChange={handleChange("panel1")}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>
            {/* <b>Lead #:</b> {location?.state?.lead_id} */}
            <b>Lead #:</b> {res_get?.data?.lead?.unique_id}
            {/* <b>Lead #:</b> {useSelector(selectedLeadUniqueIdSelector)} */}

          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {errorLead ? (
              <div>{JSON.stringify(errorLead)}</div>
            ) : isLoadingLead ? (
              <div>Loading Single Lead.....</div>
            ) : res_get?.data?.lead ? (
              <DZGenericTextField
                  obj={LeadArray}
                  closeBtn={false}
                  grid={true}
                  marginTop={0}
                  setStateData={setLead}
                  state={lead}
                  onSubmit={updateLeadData}
                  setShowBackDrop={setShowBackDrop}
              />
            ) : (
              <div>Bad Code</div>
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default CustomizedAccordions;
