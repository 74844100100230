import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, { useEffect, useState } from 'react'
import {
  useGetAllLeadOfferFeedbacksQuery, BLUE_PRINTS,  useDeleteLeadOfferFeedbackMutation,

} from 'app/rkt_query/storeApis';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';
import LeadOfferFeedback from './AddUpdateLeadOfferFeedback';
import { setSelectedLeadOfferFeedback } from 'app/rkt_query/SelectedStuffReducer';
import ViewLeadOfferFeedback from './ViewLeadOfferFeedback';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';

import { Box } from '@mui/material';



const LeadOfferFeedbackList = () => {



  const dispatch = useDispatch();
  const { showDialog, hideDialog } = useJumboDialog();
  const [deleteLeadOfferFeedback, responseDelete] = useDeleteLeadOfferFeedbackMutation();
  const { data: res_get_all, } = useGetAllLeadOfferFeedbacksQuery();
  const [showMode, setShowMode] = useState(false);
  const bluePrint = BLUE_PRINTS.leadOfferFeedback
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedLeadOfferFeedback(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true); dispatch(setSelectedLeadOfferFeedback(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedLeadOfferFeedback(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedLeadOfferFeedback(row)); }
  bluePrint.handleDelete = (id) => { deleteLeadOfferFeedback(id); }

  return (
    <>
      {/* <StoreTokenCheck /> */}
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      {/* <Button variant='contained' sx={{ width: '15%', marginBottom: '1%' }} onClick={handleBack} >Back</Button> */}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7 }}>
        <JumboDemoCard
          title={"LeadOfferFeedback"}
          wrapperSx={{ p: 0, backgroundColor: 'background.paper', '&:last-child': { pb: 0 } }} >
          <div style={{ width: '100%' }}>
            <DataTable
              showAutoComplete={true}
              btnTitleAdd="LeadOfferFeedback"
              data={res_get_all?.data?.lead_offer_feedbacks}
              bluePrint={bluePrint}
              ViewModel={() => <ViewLeadOfferFeedback />}
              ModalContent={() => <LeadOfferFeedback onClose={hideDialog} />}
              showAddButton={false}

             
        
            
            />
        
          </div>
        </JumboDemoCard>
      </Box>
    </>
  )
}


export default LeadOfferFeedbackList