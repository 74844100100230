
import { selectedPopularHotelSelector } from 'app/rkt_query/SelectedStuffReducer';
import { selectedLanguageSelector } from 'app/rkt_query/SettingsReducer';
import { useGetPopularHotelQuery } from 'app/rkt_query/storeApis';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import DZGenericView from '@jumbo/dz-components/dzgeneric/DZGenericView';
const ViewPopularHotel = ({ selectId }) => {

    const selectedPopularHotel = useSelector(selectedPopularHotelSelector);
    const [PopularHotelId, setPopularHotelId] = useState(selectId);
    const { data: res_get, isLoading: isLoadingPopularHotel, error: errorPopularHotel } = useGetPopularHotelQuery(PopularHotelId);
    // const ViewPopularHotel = ({showId}) => {
  
    const selectedLanguage = useSelector(selectedLanguageSelector);
    useEffect(() => {
   
        setPopularHotelId(selectedPopularHotel ? selectedPopularHotel?.id : selectId)
    }, [])
    const popularHotelArray =
        [
            { label: 'Country', data: res_get?.data?.popular_hotel?.country?.name[selectedLanguage] },
            { label: 'Name', data: res_get?.data?.popular_hotel?.name[selectedLanguage] },
       

        ]

    return (
        <div>

            {errorPopularHotel ? <div>{JSON.stringify(errorPopularHotel)}</div>
                : isLoadingPopularHotel ? <div>Loading Single PopularHotel.....</div>
                    : res_get?.data?.popularHotel ?
                        <DZGenericView obj={popularHotelArray}  closeBtn={true} /> : <div>Bad Code</div>}

        </div>
    )
}




export default ViewPopularHotel

