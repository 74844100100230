import LanguageSwitcher from "@jumbo/dz-components/buttons/LanguageSwitcher";
import {
  Button,
  TextField,
  Fab,
  Box,
  Radio,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import {
  selectedLeadUserSelector,
  setSelectedLeadUser,
  selectedCompanySelector,
  setSelectedCompany,
} from "app/rkt_query/SelectedStuffReducer";
import {
  LANGUAGES,
  selectedLanguageSelector,
  setSelectedLanguage,
} from "app/rkt_query/SettingsReducer";
import {
  BLUE_PRINTS,
  useAddLeadUserMutation,
  useGetAllRegionsQuery,
  useGetLeadUserQuery,
  useUpdateLeadUserMutation,
} from "app/rkt_query/storeApis";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DZDropdown, {
  DZSimpleDropDown,
} from "../../../@jumbo/dz-components/dropdowns/DZDropdown";
import DZTextField, {
  DZDateField,
} from "../../../@jumbo/dz-components/textboxes/DZTextField";
import { useJumboDialog } from "../../../@jumbo/components/JumboDialog/hooks/useJumboDialog";
import Snackbar from "@jumbo/dz-components/snackbar/Snackbar";
import DZTextValidation from "@jumbo/dz-components/textboxes/DZTextValidation";
import SimpleBackdrop from "@jumbo/dz-components/backdrop/BackDrop";
import { Config } from "constant";
import { DZImageCircle } from "@jumbo/dz-components/imageCircle/DZImage";
import {
  addUpdateDivStyleGlobal,
  dialogButtonsStyle,
} from "@jumbo/dz-components/styles/styles";
import Div from "@jumbo/shared/Div";
import Grid from "@mui/material/Grid";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import {
  DzNoBackgroundTranslatable,
  DzNoBackgroundTranslatableModule,
} from "@jumbo/dz-components/labels/DZLabel";
import useResponseError from "@jumbo/dz-components/custom-hooks/useResponseError";
import { useSnackBarManager } from "app/hooks/useSnackBarManager";
import DZRadioGroup from "@jumbo/dz-components/radio-button/DzRadioGroup";

const AddUpdateLeadUser = ({ onClose }) => {
  const dispatch = useDispatch();
  const selectedLeadUser = useSelector(selectedLeadUserSelector);
  console.log(selectedLeadUser);
  const { data: res_get } = useGetLeadUserQuery(selectedLeadUser?.id);
  const { data: res_get_all_regions } = useGetAllRegionsQuery();
  const [updateLeadUser, responseUpdate] = useUpdateLeadUserMutation();
  const [addLeadUser, responseAdd] = useAddLeadUserMutation();
  const bluePrint = BLUE_PRINTS.leadUser;
  const [leadUserState, setLeadUserState] = useState(null);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const { fnShowSnackBar } = useSnackBarManager();

  const addUpdateLeadUserData = async (action) => {
    const formData = new FormData();

    formData.append("id", res_get?.data?.lead_user?.id);
    formData.append("first_name", leadUserState.first_name);
    formData.append("last_name", leadUserState.last_name);
    formData.append("email", leadUserState.email);
    formData.append("membership_type", leadUserState.membership_type);
    formData.append("password", leadUserState.password);
    formData.append("mobile", leadUserState.mobile);
    formData.append("country_code", leadUserState.country_code);

    const res =
      action === "add"
        ? await addLeadUser(formData)
        : await updateLeadUser(formData);

    if (res?.data?.message) {
      // fnShowSnackBar(res?.data?.message || "Done successfully");
    } else {
      fnShowSnackBar(res?.error?.data?.message, "error");
    }
  };
  const { error, resetCount } = useResponseError(responseAdd, responseUpdate);

  useEffect(() => {
    setLeadUserState(selectedLeadUser ? res_get?.data?.lead_user : bluePrint);
  }, [res_get]);
  const regions = res_get_all_regions?.data?.regions;
  return (
    <div>
      {showBackDrop && (
        <SimpleBackdrop
          responseAdd={responseAdd}
          responseUpdate={responseUpdate}
        />
      )}
      <Snackbar
        responseAdd={
          responseAdd?.isSuccess === true ? responseAdd : responseUpdate
        }
      />

      {leadUserState ? (
        <div style={addUpdateDivStyleGlobal}>
          {/* <Grid item xs={12} md={12} lg={12}>
                                <Div sx={{ mb: 0.5, mt: 1 }}>
                                  <Card
                                    sx={{
                                      overflow: "visible",
                                      mt: 4,
                                      border: 1,
                                    }}
                                  >
                                    <CardContent>
                                      <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={leadUserState?.defaultAirport}
                                        onChange={(e) => {
                                          setLeadUserState((x) => ({
                                            ...x,
                                            defaultAirport: e.target.value,
                                          }));
                                        }}
                                      >
                                        {leadUserState?.airports_id?.map(
                                          (input, index) => {
                                            return (
                                              <>
                                                <Grid container spacing={3.75}>
                                                  <Grid  item xs={12} md={6} lg={6} >
                                                    <Div sx={{ mb: 0.5, mt: 1 }} >
                                                      <FormControlLabel value={input?.id} control={<Radio />} label={input?.name}/>
                                                    </Div>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    lg={6}
                                                  >
                                                    <Div
                                                      sx={{ mb: 0.5, mt: 1 }}
                                                    >
                                                      {leadUserState?.defaultAirport !=
                                                        input?.id && (
                                                        <TextField
                                                          fullWidth
                                                          label="Price"
                                                          type="number"
                                                          inputProps={{
                                                            min: 1,
                                                          }}
                                                          name="price"
                                                          variant="outlined"
                                                          noOfRows={1}
                                                          value={
                                                            leadUserState
                                                              ?.airportPrices[
                                                              index
                                                            ] || 0
                                                          }
                                                          onChange={(e) => {
                                                            const newPrices = [
                                                              ...leadUserState?.airportPrices,
                                                            ];
                                                            newPrices[index] =
                                                              e.target.value;
                                                              setLeadUserState(
                                                              (x) => ({
                                                                ...x,
                                                                airportPrices:
                                                                  newPrices,
                                                              })
                                                            );
                                                          }}
                                                        />
                                                      )}
                                                    </Div>
                                                  </Grid>
                                                </Grid>
                                              </>
                                            );
                                          }
                                        )}
                                      </RadioGroup>
                                    </CardContent>
                                  </Card>
                                </Div>
                              </Grid> */}

          <DZRadioGroup
            error={error?.membership_type}
            label="Membership Type"
            value={leadUserState?.membership_type || "basic"}
            onChange={(e) => {
              setLeadUserState((x) => ({
                ...x,
                membership_type: e.target.value,
              }));
            }}
            options={[
              { label: "Basic", value: "basic" },
              { label: "Premium", value: "premium" },
            ]}
          />
          <Grid item xs={12} md={6} lg={6}>
            <Div sx={{ mb: 0.5, mt: 1 }}>
              <DZDropdown
                placeholder={"Select Region"}
                fieldTextName={"title"}
                value={leadUserState?.region_id || ""}
                data={regions}
                onChange={(e) => {
                  setLeadUserState((x) => ({
                    ...x,
                    region_id: e.target.value,
                  }));
                }}
              />
            </Div>
          </Grid>
          <DZTextValidation
            error={error?.first_name}
            label="First Name"
            variant="outlined"
            value={leadUserState?.first_name}
            onChange={(e) => {
              setLeadUserState((x) => ({ ...x, first_name: e.target.value }));
            }}
            placeholder="First Name"
          />
          <DZTextValidation
            error={error?.last_name}
            label="Last Name"
            variant="outlined"
            value={leadUserState?.last_name}
            onChange={(e) => {
              setLeadUserState((x) => ({ ...x, last_name: e.target.value }));
            }}
            placeholder="Last Name"
          />

          <DZTextValidation
            error={error?.email}
            label="Email"
            variant="outlined"
            value={leadUserState?.email}
            onChange={(e) => {
              setLeadUserState((x) => ({ ...x, email: e.target.value }));
            }}
            placeholder="Email"
          />

          <DZTextValidation
            error={error?.mobile}
            label="Mobile"
            variant="outlined"
            value={leadUserState?.mobile}
            onChange={(e) => {
              setLeadUserState((x) => ({ ...x, mobile: e.target.value }));
            }}
            placeholder="Mobile"
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            value={leadUserState?.password}
            {...(error?.password && {
              error: true,
              helperText: error?.password,
            })}
            onChange={(e) => {
              setLeadUserState((x) => ({ ...x, password: e.target.value }));
            }}
            placeholder="Password"
          />

          <div style={dialogButtonsStyle}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                dispatch(setSelectedLeadUser(null));
              }}
            >
              Cancel
            </Button>
            {selectedLeadUser?.id ? (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadUserData();
                  setShowBackDrop(true);
                  resetCount();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => {
                  addUpdateLeadUserData("add");
                  setShowBackDrop(true);
                  resetCount();
                  dispatch(setSelectedLeadUser(null));
                }}
              >
                Add
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>Loading....</div>
      )}
    </div>
  );
};

export default AddUpdateLeadUser;
