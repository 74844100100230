import JumboDemoCard from '@jumbo/components/JumboDemoCard';
import React, {  useState } from 'react'

import { useGetAllPopularHotelsQuery, BLUE_PRINTS, useDeletePopularHotelMutation,  } from 'app/rkt_query/storeApis';
import { useDispatch } from 'react-redux';

import DataTable from '../../../@jumbo/dz-components/data-table/DataTable';

import { setSelectedPopularHotel } from 'app/rkt_query/SelectedStuffReducer';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Snackbar from '@jumbo/dz-components/snackbar/Snackbar';


import ViewPopularHotel from './ViewPopularHotel';
import AddUpdatePopularHotel from './AddUpdatePopularHotel.js';
import SimpleBackdrop from '@jumbo/dz-components/backdrop/BackDrop';


const PopularHotelList = () => {

  const dispatch = useDispatch();


  const { showDialog, hideDialog } = useJumboDialog();
  const [deletePopularHotel, responseDelete] = useDeletePopularHotelMutation();



  const { data: res_get_all, } = useGetAllPopularHotelsQuery();
  const [showMode, setShowMode] = useState(false);
  const bluePrint = BLUE_PRINTS.popularHotel
  bluePrint.addRecord = () => { setShowMode(false); dispatch(setSelectedPopularHotel(null)); }
  bluePrint.showRecord = (row) => { setShowMode(true);  dispatch(setSelectedPopularHotel(row)); }
  bluePrint.editRecord = (row) => { setShowMode(false); dispatch(setSelectedPopularHotel(row)); }
  bluePrint.deleteRecord = (row) => { dispatch(setSelectedPopularHotel(row)); }
  bluePrint.handleDelete = (id) => { deletePopularHotel(id); }
  return (
    <>
    
      <span>{showMode}</span>
      {responseDelete.isLoading === true && <SimpleBackdrop responseAdd={responseDelete} />}
      {responseDelete?.isSuccess === true && <Snackbar responseAdd={responseDelete} />}
      <JumboDemoCard
        title="PopularHotels"
        wrapperSx={{
          p: 0,
          backgroundColor: "background.paper",
          "&:last-child": { pb: 0 },
        }}
        sx={{ mt: 5 }}
      >

        <div style={{ width: "100%" }}>
          <DataTable

            btnTitleAdd="PopularHotel"
            data={res_get_all?.data?.popular_hotels}
            bluePrint={bluePrint}
            ViewModel={() => <ViewPopularHotel />}
            ModalContent={() => <AddUpdatePopularHotel onClose={hideDialog} fullscreen={true} />}
            showAddButton={true}
          
          />
         </div>
      </JumboDemoCard>
    </>
  );
}


export default PopularHotelList